
export const getCurrentSlideData = item => {
  const {
    name,
    objectID,
    type,
    description,
    thumbnails,
    link,
    XFrameOptions,
    _tags,
    slideID
  } = item;

  return {
    ...(name && { name }),
    ...(objectID && { objectID }),
    ...(type && { type }),
    ...(description && { description }),
    ...(thumbnails && { thumbnails }),
    ...(link && { link }),
    ...(XFrameOptions && { XFrameOptions }),
    ...(_tags && { _tags }),
    ...{ slideID: slideID || objectID }
  };
};

export const getQueryInTypes = payload => {
  const { types = {}, typesOrdered = [] } = payload;

  if (typesOrdered.length) {
    typesOrdered.forEach((type, index) => {
      if (types[type.value]) {
        types[type.value].hide = !!type.hide;
        types[type.value].order = index;
      }
    });

    return Object.values(types).sort((a, b) => a.order - b.order);
  }

  return Object.values(types);
};

export const getQueryInTags = payload => {
  const { tags = {}, tagsOrdered = [], contentsOrdered = {} } = payload;

  if (tagsOrdered.length) {
    tagsOrdered.forEach((tag, index) => {
      if (tags[tag.value]) {
        tags[tag.value].hide = !!tag.hide;
        tags[tag.value].isEditedName = tag.isEditedName;
        tags[tag.value].order = index;

        if (Object.keys(contentsOrdered).length) {
          tags[tag.value].hitsWithTag = tags[tag.value].hitsWithTag.sort(
            (a, b) => {
              return (
                contentsOrdered[`${tag.value}${a.objectID}`] -
                contentsOrdered[`${tag.value}${b.objectID}`]
              );
            }
          );
        }
      }
    });

    return Object.values(tags).sort((a, b) => a.order - b.order);
  }

  return Object.values(tags);
};

export const getFilteredTypes = payload => {
  const { queryInTypes, primaryFilter, tagFilter, search = {} } = payload;
  let filteredTypes = [...queryInTypes.map(item => ({ ...item }))].filter(
    type => type.hitsWithType.length
  );

  if (primaryFilter === 'tag' && tagFilter) {
    filteredTypes = filteredTypes.filter(type => type.tags.includes(tagFilter));

    filteredTypes = filteredTypes.map(type => {
      type.hitsWithType = type.hitsWithType.filter(item =>
        item._tags.includes(tagFilter)
      );
      return type;
    });
  }

  if (search?.tags?.length) {
    filteredTypes = filteredTypes.filter(filteredType => {
      if (search.searchAndOr) {
        return search.tags.some(
          tag => tag === filteredType.value || filteredType.tags.includes(tag)
        );
      }
      return search.tags.every(
        tag => tag === filteredType.value || filteredType.tags.includes(tag)
      );
    });
  }

  return filteredTypes;
};

export const getFilteredTags = payload => {
  const {
    queryInTags,
    primaryFilter,
    typeFilter,
    search = {},
    mediaLibHiddenTags = []
  } = payload;
  let filteredTags = [...queryInTags.map(item => ({ ...item }))].filter(
    tag => tag.hitsWithTag.length
  );

  if (primaryFilter === 'type' && typeFilter) {
    filteredTags = filteredTags.filter(tag => tag.types.includes(typeFilter));
    filteredTags = filteredTags.map(tag => {
      tag.hitsWithTag = tag.hitsWithTag.filter(
        item => item.type === typeFilter
      );
      return tag;
    });
  }

  if (search?.tags?.length) {
    const searchStrategy = (tag, filteredTag) =>
      tag === filteredTag.value ||
      filteredTag.types.includes(tag) ||
      (mediaLibHiddenTags.includes(tag) && filteredTag.value === 'Other');

      filteredTags = filteredTags.filter(filteredTag => {
        if (search.searchAndOr) {
          return search.tags.some(tag => searchStrategy(tag, filteredTag));
        }
        return search.tags.every(tag => searchStrategy(tag, filteredTag));
      });

    filteredTags = filteredTags.map(filteredTag => {
      filteredTag.hitsWithTag = filteredTag.hitsWithTag.filter(
        filteredResult => {
          if (search.searchAndOr) {
            return search.tags.some(
              tag =>
                tag === filteredResult.type ||
                filteredResult.searchableTags.includes(tag)
            );
          }
          return search.tags.every(
            tag =>
              tag === filteredResult.type ||
              filteredResult.searchableTags.includes(tag)
          );
        }
      );

      return filteredTag;
    });
  }

  return filteredTags;
};

export const getFilteredResults = payload => {
  const {
    primaryFilter,
    typeFilter,
    tagFilter,
    queryInTypes,
    queryInTags,
    types,
    tags,
    typeTags,
    tagTypes,
    mediaLibTags = [],
    tagsOrdered = [],
    contentsOrdered = {},
    search = {}
  } = payload;
  let filteredResults = [];

  const mediaLibTagsOrders = {};

  mediaLibTags.forEach((tag, index) => (mediaLibTagsOrders[tag] = index + 1));

  if (primaryFilter === 'type') {
    if (typeFilter && tagFilter) {
      filteredResults =
        typeTags[`${typeFilter}${tagFilter}`]?.hitsWithType || [];
    } else if (typeFilter) {
      filteredResults = types[typeFilter]?.hitsWithType || [];
    } else if (tagFilter) {
      filteredResults = tags[tagFilter]?.hitsWithTag || [];
    } else {
      queryInTypes.forEach(
        type => (filteredResults = [...filteredResults, ...type.hitsWithType])
      );
    }
  } else if (primaryFilter === 'tag') {
    if (typeFilter && tagFilter) {
      filteredResults =
        tagTypes[`${tagFilter}${typeFilter}`]?.hitsWithTag || [];
    } else if (typeFilter) {
      filteredResults = types[typeFilter]?.hitsWithType || [];
    } else if (tagFilter) {
      filteredResults = tags[tagFilter]?.hitsWithTag || [];
    } else {
      queryInTags.forEach(
        tag => (filteredResults = [...filteredResults, ...tag.hitsWithTag])
      );
    }
  }

  filteredResults = filteredResults.reduce(
    (a, v) => ({ ...a, [v.objectID]: v }),
    {}
  );
  filteredResults = Object.values(filteredResults);

  if (search?.tags?.length) {
    filteredResults = filteredResults.filter(filteredResult => {
      if (search.searchAndOr) {
        return search.tags.some(
          tag =>
            tag === filteredResult.type ||
            filteredResult.searchableTags.includes(tag)
        );
      }
      return search.tags.every(
        tag =>
          tag === filteredResult.type ||
          filteredResult.searchableTags.includes(tag)
      );
    });
  }

  if (tagsOrdered.length) {
    let tagOrders = {};
    tagsOrdered.forEach((tag, index) => (tagOrders[tag.value] = index + 1));

    tagOrders = { ...mediaLibTagsOrders, ...tagOrders };

    filteredResults = filteredResults
      .map(item => {
        item._tags = (item._tags || []).sort(
          (a, b) => tagOrders[a] - tagOrders[b]
        );
        return item;
      })
      .sort((a, b) => {
        const aTag = a._tags[0];
        const bTag = b._tags[0];
        const aSortKey = +`${tagOrders[aTag]}${
          contentsOrdered[`${aTag}${a.objectID}`] || '0'
        }`;
        const bSortKey = +`${tagOrders[bTag]}${
          contentsOrdered[`${bTag}${b.objectID}`] || '0'
        }`;

        return aSortKey - bSortKey;
      });
  } else {
    filteredResults = filteredResults
      .map(item => {
        item.tagsWithOrder = (item._tags || []).map(
          tag => mediaLibTagsOrders[tag]
        );
        return item;
      })
      .sort((a, b) => {
        const aSortKey = `${Math.min(...a.tagsWithOrder) || 0} ${a.name}`
          .trim()
          .toLowerCase();
        const bSortKey = `${Math.min(...b.tagsWithOrder) || 0} ${b.name}`
          .trim()
          .toLowerCase();
        return aSortKey?.localeCompare(bSortKey, 'en', { numeric: true });
      });
  }

  return filteredResults;
};
