import * as types from './types';

const defaultState = {
  editDialog: { open: false, mediaItemId: null },
  elementLinkDialog: {
    open: false
  },
  createLink: false
};

export default function simpleValues(state = defaultState, action) {
  switch (action.type) {
    case types.ON_SIMPLE_VALUE_CHANGED:
      return { ...state, [action.id]: action.value };
    default:
      return state;
  }
}
