import * as types from './types';

export default function launchdarkly(state = {}, action) {
  switch (action.type) {
    case types.ON_LD_INIT:
      return {
        ...state,
        client: action.client
      };

    case types.ON_LD_CHANGE:
      return {
        ...state,
        flags: { ...action.flags }
      };
    default:
      return state;
  }
}
