import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  videos: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const univVideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_UNIV_VIDEOS: {
      const { videos, status, error, query } = action.payload;
      const newVideos = getNewList(query, state.videos, videos);
      const indexes = getListIndexes(newVideos);
      return {
        ...state,
        videos: newVideos,
        status,
        error,
        indexes,
        noResults: !newVideos.length
      };
    }
    case types.REFRESH_UNIV_VIDEOS: {
      const { videoId, video } = action.payload;
      let newVideos = [...state.videos];

      newVideos[state.indexes[videoId]] = video;
      newVideos = newVideos.sort((a, b) => a.order - b.order);
      const indexes = getListIndexes(newVideos);

      return {
        ...state,
        videos: newVideos,
        indexes,
        noResults: !newVideos.length
      };
    }
    case types.SET_UNIV_VIDEOS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_UNIV_VIDEOS:
      return { ...initialState };
    default:
      return state;
  }
};
