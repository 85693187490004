/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Bookmark = props => {
  const { strokeWidth, htmlColor, filled } = props;
  return (
    <SvgIcon
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 16L6.38259 10.7528L1 16V1H12V16Z"
        stroke={htmlColor || '#8F0339'}
        strokeWidth={strokeWidth || '1.5'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        fill={filled ? htmlColor || '#8F0339' : 'none'}
      />
    </SvgIcon>
  );
};

export default Bookmark;
