import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  experience: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const singleShareExperienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SINGLE_SHARE_EXPERIENCE:
      return { ...state, ...action.payload };
    case types.REFRESH_SINGLE_SHARE_EXPERIENCE:
      return { ...state, ...action.payload };
    case types.SET_SINGLE_SHARE_EXPERIENCE_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_SINGLE_SHARE_EXPERIENCE:
      return { ...initialState };
    default:
      return state;
  }
};
