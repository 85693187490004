import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Square = props => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 18 18" fill="none" {...props}>
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="1"
        stroke="white"
        fill="none"
      />
    </SvgIcon>
  );
};

export default Square;
