const USER_FIELDS = {
  NAME: 'name',
  WORK_PHONE: 'workPhone',
  CELL_PHONE: 'cellPhone',
  EMAIL: 'email',
  DARK_MODE_ENABLED: 'darkModeEnabled',
  PASSWORD: 'password',
  GENERAL_NOTIFICATIONS_SMS_ENABLED: 'generalNotificationsSMSEnabled',
  GENERAL_NOTIFICATIONS_EMAIL_ENABLED: 'generalNotificationsEmailEnabled',
  GENERAL_NOTIFICATIONS_PUSH_ENABLED: 'generalNotificationsPushEnabled',
  CONTENT_NOTIFICATIONS_SMS_ENABLED: 'contentNotificationsSMSEnabled',
  CONTENT_NOTIFICATIONS_EMAIL_ENABLED: 'contentNotificationsEmailEnabled',
  CONTENT_NOTIFICATIONS_PUSH_ENABLED: 'contentNotificationsPushEnabled',
  ENGAGEMENT_NOTIFICATIONS_SMS_ENABLED: 'engagementNotificationsSMSEnabled',
  ENGAGEMENT_NOTIFICATIONS_EMAIL_ENABLED: 'engagementNotificationsEmailEnabled',
  ENGAGEMENT_NOTIFICATIONS_PUSH_ENABLED: 'engagementNotificationsPushEnabled'
};

export default USER_FIELDS;
