import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EyeIcon = props => {
  const { style, htmlColor, ...rest } = props;

  return (
    <SvgIcon
      width="45"
      height="32"
      viewBox="0 0 45 32"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: '1em',
        width: '1.4169921875em',
        fontSize: '1em',
        ...style
      }}
      fill={htmlColor}
      {...rest}
    >
      <path d="M22.667 0c-5.129 0-10.148 2.48-14.913 6.669-3.061 2.691-5.545 5.64-7.293 8.155l-0.197 0.288-0.181 0.375c-0.108 0.26-0.109 0.429-0.005 0.809l0.175 0.576 0.201 0.295c1.754 2.525 4.238 5.474 7.299 8.165 4.765 4.189 9.784 6.669 14.913 6.669s10.148-2.48 14.913-6.669c3.061-2.691 5.545-5.64 7.293-8.155l0.197-0.288 0.181-0.375c0.108-0.26 0.109-0.429 0.005-0.809l-0.175-0.576-0.201-0.295c-1.754-2.525-4.238-5.474-7.299-8.165-4.765-4.189-9.784-6.669-14.913-6.669zM22.667 2.909c4.423 0 8.905 2.215 13.239 6.024l0.845 0.764c1.934 1.797 3.601 3.688 4.935 5.431l0.646 0.869-0.65 0.88c-1.521 1.987-3.481 4.171-5.776 6.189-4.334 3.809-8.816 6.024-13.239 6.024s-8.905-2.215-13.239-6.024l-0.845-0.764c-1.934-1.797-3.601-3.688-4.935-5.431l-0.649-0.872 0.652-0.877c1.521-1.987 3.481-4.171 5.776-6.189 4.334-3.809 8.816-6.024 13.239-6.024z" />
      <path d="M22.667 5.333c-5.155 0-9.333 4.776-9.333 10.667s4.179 10.667 9.333 10.667c5.155 0 9.333-4.776 9.333-10.667s-4.179-10.667-9.333-10.667zM22.667 8.381c3.682 0 6.667 3.411 6.667 7.619s-2.985 7.619-6.667 7.619c-3.682 0-6.667-3.411-6.667-7.619s2.985-7.619 6.667-7.619z" />
    </SvgIcon>
  );
};

export default EyeIcon;
