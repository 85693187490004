import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ExpStart = props => {
  return (
    <SvgIcon
      width="16"
      height="23"
      viewBox="0 0 16 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5742 17.4414L14.4578 19.2599L13.4864 9.13135"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.23215 10.2017L2.0332 17.0226L5.35336 16.2952"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0593 4.8428L15 2.35184L9.39262 1L4.59069 2.26264L1 1.57642L2.60474 6.18092"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75195 2.53613L4.75195 3.47594"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39258 1L9.62411 5.35576"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41828 20.7958C7.0384 20.8078 7.49415 20.3349 7.60629 19.7893L7.69905 19.531L7.58012 20.0887C7.57371 20.1187 7.57009 20.1493 7.56929 20.18C7.55068 20.8966 8.12195 21.5779 8.9006 21.4933L8.97007 21.4868L8.98529 21.4852C9.57428 21.4122 9.98556 20.9079 10.0403 20.3494L10.8819 16.4558C10.8874 16.4307 10.8909 16.4051 10.8924 16.3794L11.0399 13.861L11.039 13.861C11.0399 13.8525 11.0405 13.8452 11.0408 13.8398C11.0407 13.823 11.0395 13.7947 11.0385 13.783C11.0362 13.7599 11.0328 13.7418 11.0313 13.734C11.0279 13.717 11.0243 13.7035 11.023 13.6985C11.0197 13.6863 11.0164 13.6756 11.0146 13.6696C11.0104 13.6562 11.0051 13.6403 10.9998 13.6245C10.9887 13.5917 10.973 13.5463 10.9537 13.4912C10.915 13.3804 10.8596 13.2242 10.7934 13.0386C10.6608 12.667 10.4837 12.1738 10.3065 11.6818C10.1292 11.1896 9.95182 10.6982 9.81873 10.3298L9.75897 10.1644L11.231 9.55163C11.2669 9.53669 11.3009 9.5176 11.3324 9.49477L13.7669 7.72901C14.1554 7.4633 14.3339 6.97558 14.2167 6.51807C14.0863 5.99177 13.5744 5.59666 12.9941 5.74386C12.963 5.75174 12.9328 5.76258 12.9038 5.77625L11.232 6.56435L8.43043 7.43771L5.98132 7.67772L5.19049 7.68926L3.99475 7.05018L3.99475 7.05016L3.99109 7.04824C3.34441 6.70947 2.67386 7.16267 2.55166 7.79205C2.55069 7.79587 2.54971 7.79986 2.54873 7.80401C2.54248 7.83038 2.53588 7.86393 2.53099 7.90344C2.5213 7.98174 2.51769 8.08862 2.54028 8.21128C2.58742 8.46737 2.73985 8.74018 3.05909 8.94384L5.29441 10.7083L6.35325 13.8319L6.22069 16.199L5.25518 19.3106C5.24302 19.3497 5.23573 19.3902 5.23347 19.4312C5.1965 20.1017 5.674 20.7828 6.41828 20.7958ZM6.41828 20.7958C6.41803 20.7958 6.41778 20.7958 6.41753 20.7958L6.42749 20.2959L6.41904 20.7958C6.41878 20.7958 6.41853 20.7958 6.41828 20.7958Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.94207 7.2295C6.63819 7.2295 7.2025 6.59968 7.2025 5.82276C7.2025 5.04584 6.63819 4.41602 5.94207 4.41602C5.24595 4.41602 4.68164 5.04584 4.68164 5.82276C4.68164 6.59968 5.24595 7.2295 5.94207 7.2295Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ExpStart;
