import * as types from './types';

const initialState = {};

export default function iconsStore(state = initialState, action) {
  const prevItems = state[action.id] ? state[action.id] : [];
  // console.log('prevItems', state);
  let newItems = '';
  if (action.type === types.REMOVE_ICON) {
    newItems = state[action.id].filter(obj => {
      return obj.uid !== action.value;
    });
  }

  switch (action.type) {
    case types.ON_ICON_SELECT:
      const { value } = action;
      const { iconId: id, shiftKey, index } = value;

      const contetLibrary =
        state[action.id] || state.documentsSelects[action.id];

      const { lastSelected, selected } = contetLibrary;

      const activeIcons = state[action.id].active || [];

      let newSelected = {};
      let currentlySelected = id;

      if (selected) {
        newSelected = selected;
        if (shiftKey && lastSelected && lastSelected !== id) {
          const beginningPosition = selected[lastSelected];
          const currentPosition = index;
          const isBetween = (n, a, b) => {
            return (n - a) * (n - b) <= 0;
          };

          const shiftSelection = activeIcons
            .map((item, index) => ({ ...item, index }))
            .filter((item, index) => {
              return isBetween(index, beginningPosition, currentPosition);
            });

          shiftSelection.map(item => {
            newSelected[item.id] = item.index;
          });
        } else if (selected.hasOwnProperty(id)) {
          currentlySelected = false;
          delete newSelected[id];
        } else {
          newSelected[id] = index;
        }
      } else {
        newSelected = { [id]: index };
      }
      const selectActionValue = {
        ...state[action.id],
        lastSelected: currentlySelected,
        selected: newSelected
      };

      return { ...state, [action.id]: selectActionValue };
    case types.ON_ICONS_SELECT_MODE:
      const setSelectValue = {
        ...state[action.id],
        ...action.value
      };
      return { ...state, [action.id]: setSelectValue };
    case types.ON_ICONS_UNSELECT:
      return {
        ...state,
        [action.id]: { selectMode: true, selectAll: false, selected: {} }
      };
    case types.UPLOADING_ICONS:
      return { ...state, [action.id]: [...prevItems, ...action.value] };
    case types.INDEXING_COMPLETED:
      return { ...state, [action.id]: action.value };
    case types.REMOVE_ICON:
      return { ...state, [action.id]: newItems };
    case types.SET_ICONS:
      const setActionValue = {
        ...state[action.id],
        ...action.value
      };
      return { ...state, [action.id]: setActionValue };
    default:
      return state;
  }
}
