import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Undo = props => {
  return (
    <SvgIcon
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.13689 9.68256C3.29072 5.59062 5.78564 1.55558 9.70778 0.671964C13.6299 -0.21165 17.494 2.39748 18.3365 6.48941C19.179 10.5813 16.6877 14.6202 12.7656 15.5"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36037 6.94557L4.05077 9.83356L1.255 5.38623"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Undo;
