import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DownloadWhite = props => {
  return (
    <SvgIcon
      fontSize="large"
      width={16}
      height={12}
      viewBox="0 0 16 12"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Batch-Action-Bar" transform="translate(-675.000000, -14.000000)">
          <rect
            id="Rectangle"
            fill="#8F0339"
            x="0"
            y="0"
            width="1127"
            height="37"
          />
          <g
            id="Group-54"
            transform="translate(676.000000, 10.000000)"
            stroke="#FFFFFF"
          >
            <g id="Group-23" transform="translate(0.000000, 5.000000)">
              <polyline
                id="Path-2224"
                points="0 4 0 0 13.7794969 0 13.7794969 4"
              />
              <line x1="7" y1="3" x2="7" y2="11" id="Path-23" />
              <polyline id="Path-24" points="3 7 7 11 11 7" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DownloadWhite;
