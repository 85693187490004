import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Phone = props => {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3142 5.32272L14.5919 1.08483L16.2708 2.7481C16.4324 3.8584 16.4344 4.98526 16.2766 6.09502C15.355 12.578 9.30033 17.0752 2.75313 16.1398L1.07422 14.4766L5.35197 10.2387L7.03088 11.9019C9.54744 11.426 11.5127 9.4791 11.9931 6.98599L10.3142 5.32272Z"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default Phone;
