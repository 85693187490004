/* eslint-disable react/prop-types */

import React from 'react';
import { Checkbox, LabelText, FormControlLabel } from '../../..';
import { NOTIFICATION_OPTIONS } from '../../../../../../constants';

function Push(props) {
  const { name, values, onChange } = props;

  const labelMarkup = <LabelText option={NOTIFICATION_OPTIONS.PUSH} />;

  const controlMarkup = (
    <Checkbox name={name} values={values} onChange={onChange} />
  );

  return <FormControlLabel label={labelMarkup} control={controlMarkup} />;
}

export default Push;
