import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const AddRoundedIcon = props => {
  const { color = '#333333' } = props;
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <rect x="6.5625" width="1" height="14" rx="0.5" fill={color} />
      <rect
        x="14"
        y="6.5625"
        width="1"
        height="14"
        rx="0.5"
        transform="rotate(90 14 6.5625)"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AddRoundedIcon;
