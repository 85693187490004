import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import React from 'react';
import { useSelector } from 'react-redux';
import { ROLES } from '../constants/roles';
import { currentUserSelector } from '../store/v2/currentUser/selectors';

const getMenuItems = props => {
  const { auth, showHub, flags, propertyId } = props;

  const { user } = useSelector(currentUserSelector);
  const { role, propertyRoles = {} } = user;

  const hasPropertyRole = role => propertyRoles[propertyId] === role;

  const { isAuthorised } = auth;

  const propertyMenu = [
    {
      value: `/properties/${propertyId}/media`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!propertyId,
      primaryText: 'Content Library'
    },
    {
      value: `/properties/${propertyId}/experiences`,
      visible: isAuthorised && !!propertyId,
      primaryText: 'Experiences'
    },
    {
      value: `/properties/${propertyId}/integrations`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!flags['site-integrations'] &&
        !!propertyId,
      primaryText: 'Integrations'
    },
    {
      value: `/properties/${propertyId}/members`,
      visible: isAuthorised && !!propertyId,
      primaryText: 'Members'
    },
    {
      value: `/properties/${propertyId}/contacts`,
      visible: isAuthorised && !!propertyId,
      primaryText: 'Contacts'
    },
    {
      value: `/properties/${propertyId}/analytics`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!flags['analytics-tab'] &&
        !!propertyId,
      primaryText: 'Analytics'
    },
    {
      value: `/properties/${propertyId}/hub`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!showHub &&
        propertyId,
      primaryText: 'Content Hub'
    },
    {
      value: `/properties/${propertyId}/billing`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!flags['billing-tab'] &&
        !!propertyId,
      primaryText: 'Billing'
    },
    {
      value: `/properties/${propertyId}/settings`,
      visible:
        isAuthorised &&
        (role === ROLES.COACH_ADMIN || hasPropertyRole(ROLES.PROPERTY_ADMIN)) &&
        !!propertyId,
      primaryText: 'Settings'
    },
    {
      value: `/properties/${propertyId}/university`,
      visible: isAuthorised && !!flags.university && !!propertyId,
      primaryText: 'University'
    }
  ];

  return [
    {
      value: '/properties',
      visible:
        isAuthorised &&
        [ROLES.COACH_ADMIN, ROLES.PROPERTY_ADMIN, ROLES.HYBRID_USER].includes(
          role
        ) &&
        !propertyId,
      primaryText: 'Properties'
    },
    {
      value: '/media',
      visible: isAuthorised && role === ROLES.COACH_ADMIN && !propertyId,
      primaryText: 'Content Libraries'
    },
    {
      value: `/coaches`,
      visible: isAuthorised && role === ROLES.COACH_ADMIN && !propertyId,
      primaryText: 'Coaches'
    },
    {
      value: `/users`,
      visible: isAuthorised && role === ROLES.COACH_ADMIN && !propertyId,
      primaryText: 'Users'
    },
    {
      value: '/analytics',
      visible:
        isAuthorised &&
        role === ROLES.COACH_ADMIN &&
        !!flags['analytics-tab'] &&
        !propertyId,
      primaryText: 'Analytics',
      leftIcon: <ShowChartOutlinedIcon />
    },
    {
      value: `/university`,
      visible:
        isAuthorised &&
        [ROLES.COACH_ADMIN, ROLES.PROPERTY_ADMIN, ROLES.HYBRID_USER].includes(
          role
        ) &&
        !propertyId,
      primaryText: 'University'
    },
    ...propertyMenu
  ];
};

export default getMenuItems;
