import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AddTags = props => {
  return (
    <SvgIcon
      fontSize="small"
      width={58}
      height={58}
      viewBox="0 0 58 58"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="media-batch-add-tag-dialog"
          transform="translate(-803.000000, -476.000000)"
        >
          <path
            d="M817.702165,496 L826.515909,497.933624 L846.125,517.598581 L839.330967,524.411971 L830.537222,533.230769 L810.92813,513.565812 L809,504.726957 L814.350938,499.360774 L814.249468,499.462534 L817.702165,496 Z M816.738512,503.760559 C815.420495,505.082336 815.420495,507.225356 816.738512,508.547133 C818.056534,509.868905 820.193466,509.868905 821.511488,508.547133 C822.829505,507.225356 822.829505,505.082336 821.511488,503.760559 C820.193466,502.438787 818.056534,502.438787 816.738512,503.760559 Z"
            id="Combined-Shape"
            stroke="#8F0339"
            fill="#FFFFFF"
          />
          <polygon
            stroke="#8F0339"
            strokeLinecap="round"
            points="857.778749 489.190328 855.690652 487.091883 852.959581 489.83649 836.40341 506.474715 833 514.091883 840.712758 510.80542 860 491.422588"
          />
          <line
            x1="834"
            y1="511.091883"
            x2="836"
            y2="513.091883"
            id="Line-4"
            stroke="#8F0339"
          />
          <line
            x1="836"
            y1="507.091883"
            x2="840"
            y2="511.091883"
            id="Line-5"
            stroke="#8F0339"
          />
          <g transform="translate(808.000000, 476.000000)" />
          <g transform="translate(803.125000, 481.384615)" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AddTags;
