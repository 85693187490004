import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';
import Countries from '../../../config/Countries.json';
import { useContainerDimensions } from '../../../utils/useContainerDimensions';

const useStyles = makeStyles(theme => ({
  phoneContainer: {
    display: 'flex'
  },
  textField: {
    '& label': {
      color: '#000'
    },
    '& label.Mui-focused': {
      color: `${theme.primaryColor} !important`
    },
    '& .MuiInputBase-root': {
      paddingLeft: '4px'
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent',
      color: '#000',
      '&:-webkit-autofill': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:active': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      }
    },
    '& .Mui-focused .MuiOutlinedInput-input': {
      color: `${theme.primaryColor} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.primaryColor
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, .23)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, .23)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(143, 3, 57)'
      }
    },
    '@media (max-width:576px)': {
      '& .MuiOutlinedInput-input': {
        fontSize: '13px'
      },
      '& label': {
        fontSize: '13px'
      }
    }
  },
  autocompleteField: {
    '& .MuiFormControl-root': {
      minWidth: '90px !important',
      maxWidth: '100px !important'
    },
    '& label': {
      color: '#000'
    },
    '& label.Mui-focused': {
      color: `${theme.primaryColor} !important`
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent',
      color: '#000',
      '&:-webkit-autofill': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      },
      '&:-webkit-autofill:active': {
        transition: 'background-color 5000s ease-in-out 0s',
        '-webkit-text-fill-color': '#000 !important'
      }
    },
    '& .Mui-focused .MuiOutlinedInput-input': {
      color: `${theme.primaryColor} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.primaryColor
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, .23)',
        border: 'none !important'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, .23)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(143, 3, 57)'
      }
    },
    '@media (max-width:576px)': {
      '& .MuiOutlinedInput-input': {
        fontSize: '13px'
      },
      '& label': {
        fontSize: '13px'
      }
    }
  }
}));

const countries = Countries.map(country => ({
  ...country,
  label: `${country.code} ${country.iso} ${country.name}`.toLowerCase()
}));

const PhoneNumberInput = props => {
  const classes = useStyles({});
  const {
    label,
    placeholder,
    style = {},
    value,
    onChange,
    onBlur,
    errors,
    name,
    defaultValue,
    size = 'medium'
  } = props;

  const inputRef = useRef(null);
  const matches = useMediaQuery('(max-width:600px)');

  const { width: inputWidth } = useContainerDimensions(inputRef);

  const [countryCode, setCountryCode] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setPhoneNumber(defaultValue.number);
      setCountryCode(
        countries.find(
          country =>
            country.iso === defaultValue.iso &&
            country.code === defaultValue.code
        )
      );
    }
  }, [defaultValue]);

  const getPhoneNumber = number => {
    const newValue = { number: number || phoneNumber || '' };

    if (countryCode) {
      newValue.code = countryCode.code;
      newValue.iso = countryCode.iso;
    }

    return newValue;
  };

  return (
    <div style={style}>
      <TextField
        variant="outlined"
        label={label}
        ref={inputRef}
        fullWidth
        onChange={e => {
          setPhoneNumber(e.target.value);
          onChange(getPhoneNumber(e.target.value), name);
        }}
        onBlur={() => !errors[name] && onBlur && onBlur(name, getPhoneNumber())}
        value={phoneNumber}
        size={size}
        classes={{ root: classes.textField }}
        error={Boolean(errors[name])}
        helperText={errors[name]}
        InputProps={{
          fullWidth: true,
          startAdornment: (
            <InputAdornment position="start">
              <Autocomplete
                id="country-select-demo"
                disableClearable
                options={countries}
                key=""
                label={label}
                autoHighlight
                variant="outlined"
                value={countryCode}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                filterOptions={(list, { inputValue: searchText }) => {
                  if (!searchText) {
                    return list;
                  }
                  const newList = list.filter(
                    item => item.label.indexOf(searchText.toLowerCase()) > -1
                  );
                  return [...newList];
                }}
                onChange={(event, newValue) => {
                  setCountryCode(newValue);
                  onChange(getPhoneNumber(), name);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                classes={{ root: classes.autocompleteField }}
                getOptionLabel={option => isOpen ? option.label : option.code}
                renderOption={(renderProps, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...renderProps}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`${process.env.PUBLIC_URL}/icons/flags/${option.flag}`}
                      srcSet={`${process.env.PUBLIC_URL}/icons/flags/${option.flag}`}
                      alt=""
                    />
                    {!matches ? option.name : ''} ({option.iso}) {option.code}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    id="country-code"
                    {...params}
                    variant="outlined"
                    inputProps={{
                      fullWidth: true,
                      ...params.inputProps,
                      autoComplete: 'new-password'
                    }}
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      width: inputWidth,
                      marginLeft: '-4px'
                    }
                  }
                }}
              />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;