import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Audio = props => {
  return (
    <SvgIcon
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3574 15C14.0262 15 17.0003 11.866 17.0003 8C17.0003 4.13401 14.0262 1 10.3574 1"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinecap="round"
      />
      <path
        d="M10.3574 12C12.4539 12 14.1534 10.2091 14.1534 8.00001C14.1534 5.79087 12.4539 4.00001 10.3574 4.00001"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinecap="round"
      />
      <path
        d="M1 5.62739H4.11111L7.22222 1.85815V14.1082L4.11111 10.3389H1V5.62739Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Audio;
