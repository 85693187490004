import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RemoveTag = props => {
  return (
    <SvgIcon width={13} height={12} viewBox="0 0 13 12" {...props}>
      <path
        d="M5.39844 9.63136L7.35185 11.5848L9.73977 9.19594L11.585 7.35069L9.6316 5.39728"
        stroke="#8E183B"
        fill="none"
      />
      <path
        d="M7.69037 3.45592L6.25854 2.025L3.86512 1.50159L2.92829 2.43934L2.95579 2.41184L1.50195 3.86475L2.02537 6.25909L3.45629 7.68909"
        stroke="#8E183B"
        fill="none"
      />
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89896 4.89939C5.25738 4.54097 5.25738 3.96164 4.89896 3.60322C4.54055 3.24481 3.96121 3.24481 3.6028 3.60322C3.24438 3.96164 3.24438 4.54097 3.6028 4.89939C3.96121 5.25781 4.54055 5.25781 4.89896 4.89939Z"
        stroke="#8E183B"
      />
      <path fill="none" d="M11.1257 1.95966L1.95898 11.1263" stroke="#8E183B" />
    </SvgIcon>
  );
};

export default RemoveTag;
