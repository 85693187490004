import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Experiences = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8535 3.73025C17.083 4.42341 17.0313 5.12861 16.7789 5.71327L16.429 6.30396L16.4232 6.30999L14.5013 9.55273L12.5794 6.30999L12.5737 6.30396L12.2295 5.72533C12.0861 5.39382 12 5.02012 12 4.62834C12 3.06121 13.308 1.80751 14.8455 2.0245C15.7577 2.15107 16.5494 2.82012 16.8535 3.73025Z"
        fill="none"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.5012 5.61081C14.9764 5.61081 15.3617 5.20602 15.3617 4.70669C15.3617 4.20737 14.9764 3.80258 14.5012 3.80258C14.0259 3.80258 13.6406 4.20737 13.6406 4.70669C13.6406 5.20602 14.0259 5.61081 14.5012 5.61081Z"
        fill="none"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10.8751 6.74747L9.33072 7.38521L4 5.17645V17.7835L9.33072 19.9922L14.6536 17.7835L20 20V7.39298L18.487 6.76302"
        fill="none"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33301 19.8824V7.64706"
        fill="none"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 17.5294V11.8824"
        fill="none"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Experiences;
