/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Shared = props => {
  const { theme, htmlColor } = props;

  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8811 1.14453L16.3233 1.37792L16.7104 0.644531H15.8811V1.14453ZM11.3102 9.80478L10.8772 10.0548L11.3299 10.8388L11.7524 10.0382L11.3102 9.80478ZM6.31024 1.14453V0.644531H5.44421L5.87723 1.39453L6.31024 1.14453ZM7.67474 3.50791L7.95569 3.92151L8.34011 3.66038L8.10775 3.25791L7.67474 3.50791ZM1.24906 16.0031L0.749059 16.004L0.749995 16.504L1.24999 16.5031L1.24906 16.0031ZM5.24905 15.9956L5.24999 16.4956L5.74999 16.4947L5.74905 15.9947L5.24905 15.9956ZM9.68157 6.98384L10.1146 6.73384L9.83965 6.25765L9.39166 6.57647L9.68157 6.98384ZM15.4389 0.911144L10.868 9.5714L11.7524 10.0382L16.3233 1.37792L15.4389 0.911144ZM6.31024 1.64453H15.8811V0.644531H6.31024V1.64453ZM8.10775 3.25791L6.74325 0.894531L5.87723 1.39453L7.24172 3.75791L8.10775 3.25791ZM7.39378 3.09431C2.99053 6.08542 0.738715 10.4769 0.749059 16.004L1.74906 16.0022C1.7393 10.79 3.84077 6.71676 7.95569 3.92151L7.39378 3.09431ZM1.24999 16.5031L5.24999 16.4956L5.24812 15.4956L1.24812 15.5031L1.24999 16.5031ZM5.74905 15.9947C5.74196 12.2043 7.12028 9.42029 9.97148 7.39122L9.39166 6.57647C6.25444 8.8091 4.7414 11.9097 4.74905 15.9966L5.74905 15.9947ZM11.7433 9.55478L10.1146 6.73384L9.24855 7.23384L10.8772 10.0548L11.7433 9.55478Z"
        fill={htmlColor || 'rgba(0,0,0,.5)'}
      />
    </SvgIcon>
  );
};

export default Shared;
