import {
  getFirestore,
  collection,
  query,
  onSnapshot
} from 'firebase/firestore';
import * as types from './types';
import * as selectors from './selectors';
import * as initSelectors from '../initialization/selectors';
import { logError } from '../errors/actions';
import { logLoading, clearLoading } from '../loadings/actions';

export const initialize = (list, location, path, locationValue, append) => {
  return {
    type: types.INIIALIZE,
    payload: list,
    path,
    location,
    append,
    locationValue
  };
};

export const childAdded = (child, location) => {
  return {
    type: types.CHILD_ADDED,
    payload: child,
    location
  };
};

export const childChanged = (child, location) => {
  return {
    type: types.CHILD_CHANGED,
    payload: child,
    location
  };
};

export const childRemoved = (child, location) => {
  return {
    type: types.CHILD_REMOVED,
    payload: child,
    location
  };
};

export const childrenAdded = (children, location) => {
  return {
    type: types.CHILDREN_ADDED,
    payload: children,
    location
  };
};

export const childrenChanged = (children, location) => {
  return {
    type: types.CHILDREN_CHANGED,
    payload: children,
    location
  };
};

export const childrenRemoved = (children, location) => {
  return {
    type: types.CHILDREN_REMOVED,
    payload: children,
    location
  };
};

export const destroy = location => {
  return {
    type: types.DESTROY,
    location
  };
};

export const unWatch = path => {
  return {
    type: types.UNWATCH,
    path
  };
};

const getPath = (ref = {}) => {
  return ref.path;
};

export const getRef = (firebaseApp, path) => {
  if (typeof path === 'string' || path instanceof String) {
    const db = getFirestore(firebaseApp);
    return query(collection(db, path));
  }
  return path;
};

export const getLocation = path => {
  if (typeof path === 'string' || path instanceof String) {
    return path;
  }
  return getPath(path);
};

export function watchCol(
  firebaseApp,
  firebasePath,
  reduxPath = false,
  append = false
) {
  const ref = getRef(firebaseApp, firebasePath);
  let path = getLocation(firebasePath);

  if (!path && reduxPath) {
    path = reduxPath;
  }

  const location = reduxPath || path;

  return (dispatch, getState) => {
    const isInitialized = initSelectors.isInitialised(
      getState(),
      path,
      location
    );
    let initialized = false;

    if (!isInitialized) {
      dispatch(logLoading(location));
      const unsub = onSnapshot(
        ref,
        snapshot => {
          if (snapshot.size === 0) {
            dispatch(clearLoading(location));
          }

          const changes = snapshot.docChanges();

          const checkChangesAreAllAdd = changes.every(
            change => change.type === 'added'
          );

          const checkChangesAreAllChanged = changes.every(
            change => change.type === 'modified'
          );

          const checkChangesAreAllRemoved = changes.every(
            change => change.type === 'removed'
          );

          const formattedData = snapshot
            .docChanges()
            .map(child => ({ id: child.doc.id, data: child.doc.data() }));

          if (checkChangesAreAllAdd && changes.length > 1) {
            if (initialized) {
              dispatch(childrenAdded(formattedData, location));
            } else {
              initialized = true;
              dispatch(
                initialize(formattedData, location, path, unsub, append)
              );
            }
          } else if (checkChangesAreAllChanged && changes.length > 1) {
            dispatch(childrenChanged(formattedData, location));
          } else if (checkChangesAreAllRemoved && changes.length > 1) {
            dispatch(childrenRemoved(formattedData, location));
          } else {
            changes.forEach(change => {
              if (change.type === 'added') {
                if (initialized) {
                  dispatch(
                    childAdded(
                      {
                        id: change.doc.id,
                        data: change.doc.data()
                      },
                      location
                    )
                  );
                } else {
                  initialized = true;
                  dispatch(
                    initialize(
                      [
                        {
                          id: change.doc.id,
                          data: change.doc.data()
                        }
                      ],
                      location,
                      path,
                      unsub,
                      append
                    )
                  );
                }
              }
              if (change.type === 'modified') {
                dispatch(
                  childChanged(
                    {
                      id: change.doc.id,
                      data: change.doc.data()
                    },
                    location
                  )
                );
              }
              if (change.type === 'removed') {
                dispatch(
                  childRemoved(
                    {
                      id: change.doc.id,
                      data: change.doc.data()
                    },
                    location
                  )
                );
              }
            });
          }
        },
        err => {
          console.error(err);
          dispatch(logError(location, err));
        }
      );
    }
  };
}

export function unwatchCol(firebaseApp, firebasePath, reduxPath) {
  return (dispatch, getState) => {
    const location = reduxPath || getLocation(firebasePath);
    const allInitializations = selectors.getAllInitializations(getState());
    const unsubs = allInitializations[location];

    if (unsubs) {
      Object.keys(unsubs).forEach(key => {
        const unsub = unsubs[key];
        if (typeof unsub === 'function') {
          unsub();
        }
        dispatch(unWatch(location));
      });
    }
  };
}

export function destroyCol(firebaseApp, firebasePath, reduxPath = false) {
  return (dispatch, getState) => {
    const location = reduxPath || getLocation(firebasePath);
    const locations = getState().initialization[location];

    dispatch(unWatch(location));
    dispatch(destroy(location));

    if (reduxPath) {
      dispatch(destroy(reduxPath));
      unwatchCol(firebaseApp, reduxPath);
    } else if (locations) {
      Object.keys(locations).forEach(location => {
        unwatchCol(firebaseApp, location);
        dispatch(destroy(location));
      });
    }
  };
}

export function unwatchAllCol(firebaseApp, path) {
  return (dispatch, getState) => {
    const allLists = selectors.getAllCols(getState());

    Object.keys(allLists).forEach(function (key, index) {
      unwatchCol(firebaseApp, key);
      dispatch(unWatch(key));
    });
  };
}

export function unwatchAllCols(firebaseApp, path) {
  return (dispatch, getState) => {
    const allColls = selectors.getAllCols(getState());

    Object.keys(allColls).forEach(function (key, index) {
      unwatchCol(firebaseApp, key);
      dispatch(destroyCol(firebaseApp, allColls[index]));
    });
  };
}
