import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Ellipsis = props => {
  return (
    <SvgIcon
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6215 4.79578L10.7406 15L3.25876 15L2.37793 4.79578"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5752 5.53528L5.18659 13.0972"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.59078 5.53528L8.98975 13.0972"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.53528L7 13.2057"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.60439L1 3.20862L13 3.20862L13 5.60439"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.54932 2.15352L5.54932 1L8.45087 1L8.45087 2.15352"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Ellipsis;
