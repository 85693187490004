import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  savedExperienceURL: '',
  clickedNewExperience: false,
  clickedNewIcon: false,
  experienceCollectionPath: '',
  experienceCollectionName: '',
  tabStatus: false,
  dashboardStatus: true,
  selectedProperty: '',
  propertyCollectionPath: '',
  notificationStatus: false
};

export const MainHeaderDataSlice = createSlice({
  name: 'mainHeaderData',
  initialState,
  reducers: {
    setDashboard: (state, { payload }) => {
      const { dashboardStatus } = payload;
      state.dashboardStatus = dashboardStatus;
    },
    setNotification: (state, { payload }) => {
      const { notificationStatus } = payload;
      state.notificationStatus = notificationStatus;
    },
    // changeLastView: (state, { payload }) => {
    //   const { lastView } = payload;
    //   state.lastView = lastView;
    // },
    setClickedNewExperience: (state, { payload }) => {
      state.clickedNewExperience = !state.clickedNewExperience;
    },
    setClickedNewIcon: (state, { payload }) => {
      state.clickedNewIcon = !state.clickedNewIcon;
    },
    changeTabStatus: (state, { payload }) => {
      const { tab } = payload;
      state.tabStatus = tab;
    },
    setSelectedProperty: (state, { payload }) => {
      const { path } = payload;
      state.selectedProperty = path;
    },
    setPropertyCollectionPath: (state, { payload }) => {
      const { path } = payload;
      state.propertyCollectionPath = path;
    }
  }
});

export const {
  handleTab,
  createTab,
  // changeLastView,
  setDashboard,
  setNotification,
  setClickedNewExperience,
  setClickedNewIcon,
  changeTabStatus,
  setSelectedProperty,
  setPropertyCollectionPath
} = MainHeaderDataSlice.actions;

export default MainHeaderDataSlice.reducer;
