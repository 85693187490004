/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WelcomeIcon = props => {
  return (
    <SvgIcon
      width="109"
      height="99"
      viewBox="0 0 109 99"
      fill="none"
      {...props}
    >
      <path
        d="M78.6066 37.8675C78.5942 38.1434 78.3605 38.357 78.0846 38.3445C77.8088 38.3321 77.5952 38.0984 77.6076 37.8226L78.6066 37.8675ZM108.047 20.9243C108.306 21.0189 108.44 21.3058 108.345 21.5653C108.251 21.8247 107.964 21.9584 107.704 21.8639L108.047 20.9243ZM77.6076 37.8226C77.8127 33.267 79.5708 26.9224 84.2424 22.7674C88.9543 18.5765 96.5051 16.7193 108.047 20.9243L107.704 21.8639C96.3984 17.7447 89.2666 19.637 84.9069 23.5146C80.507 27.4281 78.8046 33.4711 78.6066 37.8675L77.6076 37.8226Z"
        fill="#333333"
      />
      <path
        d="M23.5193 97.4745C23.5698 97.746 23.3906 98.007 23.1192 98.0575C22.8477 98.108 22.5867 97.9288 22.5362 97.6573L23.5193 97.4745ZM41.5824 73.182C41.8575 73.2065 42.0606 73.4493 42.0361 73.7244C42.0116 73.9994 41.7688 74.2026 41.4938 74.1781L41.5824 73.182ZM22.5362 97.6573C21.7206 93.2724 21.6687 86.7646 24.2285 81.5347C25.5137 78.9087 27.4619 76.5975 30.304 75.0548C33.1446 73.5129 36.8389 72.7599 41.5824 73.182L41.4938 74.1781C36.9016 73.7694 33.4131 74.5049 30.7811 75.9336C28.1505 77.3615 26.3363 79.5029 25.1267 81.9743C22.6967 86.9392 22.7253 93.2051 23.5193 97.4745L22.5362 97.6573Z"
        fill="#333333"
      />
      <path
        d="M69.2331 39.4887L75.0381 1.06567"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.0379 45.1458L53.0117 29.5657"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0383 41.5631L41.7882 38.773L38.5382 35.9828"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0381 48.3196L6.03809 16.3196"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2143 64.3349L13.5381 50.5659"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.0822 49.5657L78.5381 46.0657C63.7381 50.4657 53.3714 66.899 50.0381 74.5657L67.5381 82.2863M80.0822 49.5657L101.038 97.0657L67.5381 82.2863M80.0822 49.5657C76.0675 52.2127 67.9381 62.4627 67.5381 82.2863"
        stroke="#333333"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M43.5381 48.5659H47.5381L49.0381 45.0659L50.5381 49.0659H54.0381L51.5381 51.5659L52.5381 55.5659L49.0381 53.5659L45.5381 55.5659L46.5381 51.5659L43.5381 48.5659Z"
        stroke="#333333"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1.67101 58.5737L3.63525 56.7701L2.79372 54.3751L5.33388 55.663L7.05259 54.0848L6.95217 56.4397L9.2468 57.9531L6.6263 58.5491L5.80937 61.1093L4.49687 58.6942L1.67101 58.5737Z"
        stroke="#333333"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M34.0381 13.0659H30.0381L28.5381 9.56592L27.0381 13.5659H23.5381L26.0381 16.0659L25.0381 20.0659L28.5381 18.0659L32.0381 20.0659L31.0381 16.0659L34.0381 13.0659Z"
        stroke="#333333"
        strokeLinejoin="round"
        fill="none"
      />
      <circle
        r="4.5"
        transform="matrix(-1 0 0 1 57.0381 12.0659)"
        stroke="#333333"
        fill="none"
      />
      <circle
        r="1.5"
        transform="matrix(-1 0 0 1 14.0381 39.0659)"
        stroke="#333333"
        fill="none"
      />
      <circle
        r="1.5"
        transform="matrix(-1 0 0 1 27.5381 65.0657)"
        stroke="#333333"
        fill="none"
      />
      <circle
        r="1.5"
        transform="matrix(-1 0 0 1 63.5381 31.0657)"
        stroke="#333333"
        fill="none"
      />
      <circle
        r="1.5"
        transform="matrix(-1 0 0 1 44.5381 23.0657)"
        stroke="#333333"
        fill="none"
      />
      <path
        d="M88.5381 68.0657C86.8714 71.399 84.5381 80.6657 88.5381 91.0657"
        stroke="#333333"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default WelcomeIcon;
