import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isElementUpdated: false,
  isDraftSaved: false,
  elements: [],
  elementRefs: {}
};

export const ElementsDataSlice = createSlice({
  name: 'experienceElementsData',
  initialState,
  reducers: {
    updateData: (state, { payload }) => {
      state.elements = payload;
    },
    addDraft: (state, { payload }) => {
      const { newElement } = payload;
      state.elements.push(newElement);
    },
    deleteDraft: (state, { payload }) => {
      const arrayWithoutDraft = state.elements.filter(
        el => el.status !== 'draft'
      );
      state.elements = arrayWithoutDraft;
    },
    triggerDataUpdate: (state, { payload }) => {
      state.isElementUpdated = payload;
    }
  }
});

export const { updateData, addDraft, deleteDraft, triggerDataUpdate } =
  ElementsDataSlice.actions;

export default ElementsDataSlice.reducer;
