import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};

const experienceInitialState = {
  experiences: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const recentExperiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECENT_EXPERIENCES: {
      const { propertyId, experiences, status, error, query } = action.payload;

      const newExperiences = getNewList(
        query,
        state[propertyId].experiences || [],
        experiences
      );
      const indexes = getListIndexes(newExperiences);
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          experiences: newExperiences,
          status,
          error,
          indexes,
          noResults: !newExperiences.length
        }
      };
    }
    case types.REFRESH_RECENT_EXPERIENCES: {
      const { propertyId, experienceId, experience } = action.payload;
      const newExperiences = [...state[propertyId].experiences];

      newExperiences[state[propertyId].indexes[experienceId]] = experience;

      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          experiences: newExperiences
        }
      };
    }
    case types.SET_RECENT_EXPERIENCES_REQUEST_STATUS: {
      const { propertyId } = action.payload;
      return {
        ...state,
        [propertyId]: { ...state[propertyId], ...action.payload }
      };
    }
    case types.RESET_RECENT_EXPERIENCES: {
      const { propertyId } = action.payload;

      if (propertyId) {
        return { ...state, [propertyId]: { ...experienceInitialState } };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
