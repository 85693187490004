import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Dot = props => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z"
      />
    </SvgIcon>
  );
};

export default Dot;
