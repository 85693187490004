export const API_EVENT_SECTIONS = {
  PROPERTIES: 'properties',
  USERS: 'users',
  MEDIA_LIBS: 'media_libs',
  NOTIFICATIONS: 'notifications',
  EXPERIENCES: 'experiences',
  PRESENTATIONS: 'presentations',
  VISUALIZATIONS: 'visualizations',
  MEMBERS: 'members',
  ICONS: 'icons',
  CONTACTS: 'contacts',
  MEDIA_ITEMS: 'media_items',
  CONTENTS: 'contents',
  SHARES: 'shares',
  ELEMENTS: 'elements',
  HOTSPOTS: 'hotspots',
  CONTENT_ELEMENTS: 'content_elements',
  UNIV_CATEGORIES: 'categories',
  UNIV_VIDEOS: 'videos',
  CONTACT_NOTES: 'contact_notes',
  CONTACT_EVENTS: 'contact_events'
};

export const API_EVENT_TYPES = {
  ADDED: 'added',
  MODIFIED: 'modified',
  REMOVED: 'removed'
};

export const API_EVENT_ACTIONS = {
  PROPERTIES_ADDED: `${API_EVENT_SECTIONS.PROPERTIES}_${API_EVENT_TYPES.ADDED}`,
  PROPERTIES_MODIFIED: `${API_EVENT_SECTIONS.PROPERTIES}_${API_EVENT_TYPES.MODIFIED}`,
  PROPERTIES_REMOVED: `${API_EVENT_SECTIONS.PROPERTIES}_${API_EVENT_TYPES.REMOVED}`,
  UNIV_CATEGORIES_ADDED: `${API_EVENT_SECTIONS.UNIV_CATEGORIES}_${API_EVENT_TYPES.ADDED}`,
  UNIV_CATEGORIES_MODIFIED: `${API_EVENT_SECTIONS.UNIV_CATEGORIES}_${API_EVENT_TYPES.MODIFIED}`,
  UNIV_CATEGORIES_REMOVED: `${API_EVENT_SECTIONS.UNIV_CATEGORIES}_${API_EVENT_TYPES.REMOVED}`,
  UNIV_VIDEOS_ADDED: `${API_EVENT_SECTIONS.UNIV_VIDEOS}_${API_EVENT_TYPES.ADDED}`,
  UNIV_VIDEOS_MODIFIED: `${API_EVENT_SECTIONS.UNIV_VIDEOS}_${API_EVENT_TYPES.MODIFIED}`,
  UNIV_VIDEOS_REMOVED: `${API_EVENT_SECTIONS.UNIV_VIDEOS}_${API_EVENT_TYPES.REMOVED}`,
  USERS_ADDED: `${API_EVENT_SECTIONS.USERS}_${API_EVENT_TYPES.ADDED}`,
  USERS_MODIFIED: `${API_EVENT_SECTIONS.USERS}_${API_EVENT_TYPES.MODIFIED}`,
  USERS_REMOVED: `${API_EVENT_SECTIONS.USERS}_${API_EVENT_TYPES.REMOVED}`,
  MEDIA_LIBS_ADDED: `${API_EVENT_SECTIONS.MEDIA_LIBS}_${API_EVENT_TYPES.ADDED}`,
  MEDIA_LIBS_MODIFIED: `${API_EVENT_SECTIONS.MEDIA_LIBS}_${API_EVENT_TYPES.MODIFIED}`,
  MEDIA_LIBS_REMOVED: `${API_EVENT_SECTIONS.MEDIA_LIBS}_${API_EVENT_TYPES.REMOVED}`,
  NOTIFICATIONS_ADDED: `${API_EVENT_SECTIONS.NOTIFICATIONS}_${API_EVENT_TYPES.ADDED}`,
  NOTIFICATIONS_MODIFIED: `${API_EVENT_SECTIONS.NOTIFICATIONS}_${API_EVENT_TYPES.MODIFIED}`,
  NOTIFICATIONS_REMOVED: `${API_EVENT_SECTIONS.NOTIFICATIONS}_${API_EVENT_TYPES.REMOVED}`,
  EXPERIENCES_ADDED: `${API_EVENT_SECTIONS.EXPERIENCES}_${API_EVENT_TYPES.ADDED}`,
  EXPERIENCES_MODIFIED: `${API_EVENT_SECTIONS.EXPERIENCES}_${API_EVENT_TYPES.MODIFIED}`,
  EXPERIENCES_REMOVED: `${API_EVENT_SECTIONS.EXPERIENCES}_${API_EVENT_TYPES.REMOVED}`,
  PRESENTATIONS_ADDED: `${API_EVENT_SECTIONS.PRESENTATIONS}_${API_EVENT_TYPES.ADDED}`,
  PRESENTATIONS_MODIFIED: `${API_EVENT_SECTIONS.PRESENTATIONS}_${API_EVENT_TYPES.MODIFIED}`,
  PRESENTATIONS_REMOVED: `${API_EVENT_SECTIONS.PRESENTATIONS}_${API_EVENT_TYPES.REMOVED}`,
  VISUALIZATIONS_ADDED: `${API_EVENT_SECTIONS.VISUALIZATIONS}_${API_EVENT_TYPES.ADDED}`,
  VISUALIZATIONS_MODIFIED: `${API_EVENT_SECTIONS.VISUALIZATIONS}_${API_EVENT_TYPES.MODIFIED}`,
  VISUALIZATIONS_REMOVED: `${API_EVENT_SECTIONS.VISUALIZATIONS}_${API_EVENT_TYPES.REMOVED}`,
  MEMBERS_ADDED: `${API_EVENT_SECTIONS.MEMBERS}_${API_EVENT_TYPES.ADDED}`,
  MEMBERS_MODIFIED: `${API_EVENT_SECTIONS.MEMBERS}_${API_EVENT_TYPES.MODIFIED}`,
  MEMBERS_REMOVED: `${API_EVENT_SECTIONS.MEMBERS}_${API_EVENT_TYPES.REMOVED}`,
  ICONS_ADDED: `${API_EVENT_SECTIONS.ICONS}_${API_EVENT_TYPES.ADDED}`,
  ICONS_MODIFIED: `${API_EVENT_SECTIONS.ICONS}_${API_EVENT_TYPES.MODIFIED}`,
  ICONS_REMOVED: `${API_EVENT_SECTIONS.ICONS}_${API_EVENT_TYPES.REMOVED}`,
  CONTACTS_ADDED: `${API_EVENT_SECTIONS.CONTACTS}_${API_EVENT_TYPES.ADDED}`,
  CONTACTS_MODIFIED: `${API_EVENT_SECTIONS.CONTACTS}_${API_EVENT_TYPES.MODIFIED}`,
  CONTACTS_REMOVED: `${API_EVENT_SECTIONS.CONTACTS}_${API_EVENT_TYPES.REMOVED}`,
  CONTACT_NOTES_ADDED: `${API_EVENT_SECTIONS.CONTACT_NOTES}_${API_EVENT_TYPES.ADDED}`,
  CONTACT_NOTES_MODIFIED: `${API_EVENT_SECTIONS.CONTACT_NOTES}_${API_EVENT_TYPES.MODIFIED}`,
  CONTACT_NOTES_REMOVED: `${API_EVENT_SECTIONS.CONTACT_NOTES}_${API_EVENT_TYPES.REMOVED}`,
  CONTACT_EVENTS_ADDED: `${API_EVENT_SECTIONS.CONTACT_EVENTS}_${API_EVENT_TYPES.ADDED}`,
  CONTACT_EVENTS_MODIFIED: `${API_EVENT_SECTIONS.CONTACT_EVENTS}_${API_EVENT_TYPES.MODIFIED}`,
  CONTACT_EVENTS_REMOVED: `${API_EVENT_SECTIONS.CONTACT_EVENTS}_${API_EVENT_TYPES.REMOVED}`,
  MEDIA_ITEMS_ADDED: `${API_EVENT_SECTIONS.MEDIA_ITEMS}_${API_EVENT_TYPES.ADDED}`,
  MEDIA_ITEMS_MODIFIED: `${API_EVENT_SECTIONS.MEDIA_ITEMS}_${API_EVENT_TYPES.MODIFIED}`,
  MEDIA_ITEMS_REMOVED: `${API_EVENT_SECTIONS.MEDIA_ITEMS}_${API_EVENT_TYPES.REMOVED}`,
  CONTENTS_ADDED: `${API_EVENT_SECTIONS.CONTENTS}_${API_EVENT_TYPES.ADDED}`,
  CONTENTS_MODIFIED: `${API_EVENT_SECTIONS.CONTENTS}_${API_EVENT_TYPES.MODIFIED}`,
  CONTENTS_REMOVED: `${API_EVENT_SECTIONS.CONTENTS}_${API_EVENT_TYPES.REMOVED}`,
  SHARES_ADDED: `${API_EVENT_SECTIONS.SHARES}_${API_EVENT_TYPES.ADDED}`,
  SHARES_MODIFIED: `${API_EVENT_SECTIONS.SHARES}_${API_EVENT_TYPES.MODIFIED}`,
  SHARES_REMOVED: `${API_EVENT_SECTIONS.SHARES}_${API_EVENT_TYPES.REMOVED}`,
  ELEMENTS_ADDED: `${API_EVENT_SECTIONS.ELEMENTS}_${API_EVENT_TYPES.ADDED}`,
  ELEMENTS_MODIFIED: `${API_EVENT_SECTIONS.ELEMENTS}_${API_EVENT_TYPES.MODIFIED}`,
  ELEMENTS_REMOVED: `${API_EVENT_SECTIONS.ELEMENTS}_${API_EVENT_TYPES.REMOVED}`,
  HOTSPOTS_ADDED: `${API_EVENT_SECTIONS.HOTSPOTS}_${API_EVENT_TYPES.ADDED}`,
  HOTSPOTS_MODIFIED: `${API_EVENT_SECTIONS.HOTSPOTS}_${API_EVENT_TYPES.MODIFIED}`,
  HOTSPOTS_REMOVED: `${API_EVENT_SECTIONS.HOTSPOTS}_${API_EVENT_TYPES.REMOVED}`,
  CONTENT_ELEMENTS_ADDED: `${API_EVENT_SECTIONS.CONTENT_ELEMENTS}_${API_EVENT_TYPES.ADDED}`,
  CONTENT_ELEMENTS_MODIFIED: `${API_EVENT_SECTIONS.CONTENT_ELEMENTS}_${API_EVENT_TYPES.MODIFIED}`,
  CONTENT_ELEMENTS_REMOVED: `${API_EVENT_SECTIONS.CONTENT_ELEMENTS}_${API_EVENT_TYPES.REMOVED}`
};
