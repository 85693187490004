import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Ellipsis = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13.5C4.82843 13.5 5.5 12.8284 5.5 12C5.5 11.1716 4.82843 10.5 4 10.5C3.17157 10.5 2.5 11.1716 2.5 12C2.5 12.8284 3.17157 13.5 4 13.5Z"
        fill={htmlColor || '#333333'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
        fill={htmlColor || '#333333'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 13.5C20.8284 13.5 21.5 12.8284 21.5 12C21.5 11.1716 20.8284 10.5 20 10.5C19.1716 10.5 18.5 11.1716 18.5 12C18.5 12.8284 19.1716 13.5 20 13.5Z"
        fill={htmlColor || '#333333'}
      />
    </SvgIcon>
  );
};

export default Ellipsis;
