/* eslint-disable react/prop-types */

import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = () => ({
  cardTitle: {
    fontSize: '16px',
    color: '#8f0339',
    lineHeight: 1
  }
});

function Title(props) {
  const { children, classes } = props;
  return (
    <Typography variant="h6" className={classes.cardTitle}>
      {children}
    </Typography>
  );
}

export default withStyles(styles)(Title);
