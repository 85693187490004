import { createSlice } from '@reduxjs/toolkit';
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import algoliasearch from 'algoliasearch/lite';

// export const queryAlgolia = createAsyncThunk(
//   'queryAlgolia/fetchByMediaLibIndex',
//   async (
//     {
//       indexId,
//       query = '',
//       tagQuery = '',
//       typeQuery = '',
//       filters = '',
//       collectionId = '',
//       algoliaKey = '',
//       algoliaAppId = ''
//     },
//     { rejectWithValue }
//   ) => {
//     const client = algoliasearch(algoliaAppId, algoliaKey);

//     const algoliaIndex = collectionId || indexId;

//     const index = client.initIndex(algoliaIndex);

//     const filtersWithoutOther = filters.includes('_tags:"Other"')
//       ? '_tags:null'
//       : filters;

//     try {
//       const responses = await Promise.all([
//         index.search({ query, filters: filtersWithoutOther }),
//         index.searchForFacetValues({
//           facetName: '_tags',
//           facetQuery: tagQuery
//         }),
//         index.searchForFacetValues({
//           facetName: 'type',
//           facetQuery: typeQuery
//         })
//       ]);

//       const response = {
//         query: responses[0],
//         validTags: responses[1],
//         validTypes: responses[2]
//       };
//       // Remove tags that are only on Audio items
//       response.validTags.facetHits = response.validTags.facetHits.filter(
//         tag => {
//           return response.query.hits.some(hit =>
//             hit._tags?.includes(tag.value)
//           );
//         }
//       );

//       // Add other tag if media items exist without _tags
//       const other = response.query.hits.filter(
//         hit => !hit._tags || !hit._tags.length
//       );

//       if (other.length) {
//         response.validTags.facetHits.push({
//           value: 'Other',
//           highlighted: 'Other',
//           count: other.length
//         });
//       }

//       return { ...response, indexId };
//     } catch (err) {
//       const error = err; // cast the error for access
//       if (!error.response) {
//         console.log(err);
//       }
//       return { indexId };
//     }
//   }
// );

// const isRejectedAction = action => {
//   return action.type.endsWith('rejected');
// };

const check = (state, index) => {
  if (!state[index]) {
    state[index] = {};
  }
};

export const ContentLibrarySlice = createSlice({
  name: 'contentLibraryData',
  initialState: {},
  reducers: {
    initIndex: (state, { payload }) => {
      const previousInitialization = state[payload] || {};
      state[payload] = {
        ...previousInitialization
      };
    },
    setSearch: (state, { payload }) => {
      const { indexId, term = '', tags = [], searchAndOr = true } = payload;
      check(state, indexId);
      state[indexId].search = {
        term,
        tags,
        searchAndOr
      };
    },
    setResetSearch: (state, { payload }) => {
      const { indexId, resetButton } = payload;
      check(state, indexId);
      if (resetButton !== undefined) {
        state[indexId].resetButton = resetButton;
      }
      if (resetButton || resetButton === undefined) {
        state[indexId].tagFilter = '';
        state[indexId].typeFilter = '';
        state[indexId].search = {};
      }
    },
    setOrder: (state, { payload }) => {
      const { indexId, menuToggle } = payload;
      check(state, indexId);
      state[indexId].order = menuToggle;
    },

    addFirestoreToQuery: (state, { payload }) => {
      const { indexId, storeMediaItems, order } = payload;

      check(state, indexId);

      const currentQuery = state[indexId].query || {};
      const { hits = [] } = currentQuery;
      const currentSearch = state[indexId].search || {};
      const { term = '', tags = [], searchAndOr = true } = currentSearch;

      const searchable = [
        'name',
        'description',
        'altText',
        'addedBy',
        'type',
        '_tags'
        // 'isEditedName'
      ];

      const mediaItems = storeMediaItems
        .filter(mediaItem => mediaItem.id !== 'keepHot')
        .map(item => {
          return { ...item.data, objectID: item.id };
        });

      let filteredMediaItems = mediaItems;
      if (term) {
        filteredMediaItems = mediaItems.filter(item =>
          searchable.some(searchableAttribute => {
            const termIncluded =
              item[searchableAttribute] &&
              !Array.isArray(item[searchableAttribute]) &&
              item[searchableAttribute]
                .toLowerCase()
                .includes(term.toLowerCase());

            const termIncludedInArray =
              item[searchableAttribute] &&
              Array.isArray(item[searchableAttribute]) &&
              item[searchableAttribute]
                .map(attributeTerm => attributeTerm.toLowerCase())
                .some(arrayTerm => arrayTerm.includes(term.toLowerCase()));

            const otherTerm =
              item._tags &&
              item._tags.length === 0 &&
              term.toLowerCase().includes('other');

            return termIncluded || termIncludedInArray || otherTerm;
          })
        );
      }
      if (tags.length) {
        filteredMediaItems = mediaItems.filter(item => {
          if (!searchAndOr) {
            if (item._tags.length) {
              return tags.some(tag => {
                return item._tags?.includes(tag);
              });
            }
            tags.includes('Other');
          }
          return (
            tags.every(tag => {
              return item._tags?.includes(tag);
            }) ||
            tags.every(tag => {
              return item.visible_by?.includes(tag.id);
            }) ||
            tags.every(tag => {
              return item.type === tag;
            }) ||
            (tags.includes('Other') && !item._tags.length)
          );
        });
      }

      let merged = [];

      for (let i = 0; i < hits.length; i++) {
        merged.push({
          ...hits[i],
          ...filteredMediaItems.find(item => item.objectID === hits[i].objectID)
        });
      }

      const notInHits = filteredMediaItems.filter(
        item => !hits.some(hit => hit.objectID === item.objectID)
      );

      if (notInHits && notInHits.length) {
        merged = [...notInHits, ...merged];
      }

      if (!state[indexId].query) {
        state[indexId].query = {};
      }

      const validData = merged.filter(item => item._tags !== undefined);

      const noTags = validData.filter(item => !item?._tags.length).length
        ? ['Other']
        : [];

      const collectedTags = [
        ...new Set(validData.map(item => item?._tags).flat(1)),
        ...noTags
      ].map(item => ({
        value: item
      }));

      const collectedTypes = [
        ...new Set(validData.map(item => item.type).flat(1))
      ].map(item => ({
        value: item
      }));

      if (!state[indexId].validTags) {
        state[indexId].validTags = {};
        state[indexId].validTags.facetHits = collectedTags;
      } else if (state[indexId].validTags.facetHits) {
        state[indexId].validTags.facetHits = collectedTags;
      }

      if (!state[indexId].validTypes) {
        state[indexId].validTypes = {};
        state[indexId].validTypes.facetHits = collectedTypes;
      } else if (state[indexId].validTypes.facetHits) {
        state[indexId].validTypes.facetHits = collectedTypes;
      }

      const sortOrder = state[indexId].order || {};
      const sortKey = Object.keys(sortOrder);
      const sortValue = Object.values(sortOrder);
      const key = sortKey[0];

      if (key) {
        merged.sort((a, b) => {
          if (
            key !== 'assigneesNames' &&
            key !== '_tags' &&
            key !== 'dateAdded' &&
            key !== 'fileSize'
          ) {
            if (sortValue[0]) {
              if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
                return -1;
              }
              if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (b[key]?.toLowerCase() < a[key]?.toLowerCase()) {
              return -1;
            }
            if (b[key]?.toLowerCase() > a[key]?.toLowerCase()) {
              return 1;
            }
            return 0;
          }
          if (key === 'fileSize') {
            a[key] = a[key] === '- -' ? 0 : a[key];
            b[key] = b[key] === '- -' ? 0 : b[key];

            if (sortValue[0]) {
              if (a[key] < b[key]) {
                return -1;
              }
              if (a[key] > b[key]) {
                return 1;
              }
              return 0;
            }
            if (b[key] < a[key]) {
              return -1;
            }
            if (b[key] > a[key]) {
              return 1;
            }
            return 0;
          }
          if (key === 'dateAdded') {
            let c = a[key];
            let d = b[key];
            c = (c && !(c instanceof Date) && c.toDate()) || c;
            d = (d && !(d instanceof Date) && d.toDate()) || d;

            if (sortValue[0]) {
              if (c < d) {
                return -1;
              }
              if (c > d) {
                return 1;
              }
              return 0;
            }
            if (d < c) {
              return -1;
            }
            if (d > c) {
              return 1;
            }
            return 0;
          }
          if (sortValue[0]) {
            if ((a[key]?.length || 0) < (b[key]?.length || 0)) {
              return -1;
            }
            if ((a[key]?.length || 0) > (b[key]?.length || 0)) {
              return 1;
            }
            return 0;
          }

          // Any other arrays should be sorted except of _tags?
          if ((b[key]?.length || 0) < (a[key]?.length || 0)) {
            return -1;
          }
          if ((b[key]?.length || 0) > (a[key]?.length || 0)) {
            return 1;
          }
          return 0;
        });
      }
      state[indexId].merged = merged;
    }
  }
  // ,
  // extraReducers: builder => {
  //   builder
  //     .addCase(queryAlgolia.fulfilled, (state, { payload }) => {
  //       const { indexId, ...rest } = payload;

  //       state[indexId] = {
  //         ...state[indexId],
  //         ...rest,
  //         lastSearch: new Date(),
  //         tagFilter: '',
  //         typeFilter: ''
  //       };
  //     })
  //     .addMatcher(isRejectedAction, (state, action) => {
  //       const { arg } = action.meta;
  //       const { indexId } = arg;
  //       check(state, action.payload);
  //       if (action.payload && indexId) {
  //         state[indexId].error =
  //           action.payload?.errorMessage || action.error?.message;
  //       }
  //       console.log(
  //         'redux:',
  //         action.payload?.errorMessage || action.error?.message
  //       );
  //     });
  // }
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setResetSearch,
  setFilter,
  initIndex,
  addFirestoreToQuery,
  setOrder
} = ContentLibrarySlice.actions;

export default ContentLibrarySlice.reducer;
