import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector, shallowEqual } from 'react-redux';
import makeLoadable from '../MyLoadable';
import config from '../../config';
import { isAuthorised } from '../../utils/auth';
import { currentUserSelector } from '../../store/v2/currentUser/selectors';
import LoadingSplash from '../LoadingComponent/LoadingSplash';
import { REQUEST_STATUSES } from '../../constants/requestStatuses';

const HYBRID_USER = 'Hybrid User';

export const RestrictedRoute = props => {
  const {
    featureFlag,
    type,
    component: Component,
    restrictedTo = '',
    computedMatch,
    location,
    ...rest
  } = props;

  const authorisedStore = isAuthorised();
  const flags =
    useSelector(state => state.launchdarkly?.flags, shallowEqual) || {};

  const { user, status: userStatus } = useSelector(currentUserSelector);
  const {
    role,
    isAdmin,
    properties = [],
    propertyRoles = {},
    isAuthorized
  } = user;

  const MyLoadable = (opts, preloadComponents) =>
    makeLoadable({ ...opts, firebase: config.firebaseLoad }, preloadComponents);

  const AsyncDoesNotExist = MyLoadable({
    loader: () => import('../../Dashboard/pages/DoesNotExist')
  });

  return (
    <>
      {(!!isAuthorized || !!authorisedStore) &&
        userStatus !== REQUEST_STATUSES.SUCCEEDED &&
        !user.id && <LoadingSplash />}
      <Route
        {...rest}
        render={renderProps => {
          const { match = {} } = renderProps;
          const { uid = null } = match.params;

          if (type === 'private' && !isAuthorized && !authorisedStore) {
            return <Redirect to={{ pathname: '/signin' }} />;
          }

          if (featureFlag && Object.keys(flags).length && !flags[featureFlag]) {
            return (
              <AsyncDoesNotExist
                message="You don't have credentials to access this page."
                {...renderProps}
              />
            );
          }

          if (
            type === 'private' &&
            !isAdmin &&
            uid &&
            properties.indexOf(uid) < 0
          ) {
            return (
              <AsyncDoesNotExist
                logout
                message="You don't have credentials to access this page."
                {...renderProps}
              />
            );
          }

          if (
            type === 'private' &&
            !isAdmin &&
            !uid &&
            !!restrictedTo &&
            restrictedTo !== role &&
            role !== HYBRID_USER
          ) {
            return (
              <AsyncDoesNotExist
                logout
                message="You don't have credentials to access this page."
                {...renderProps}
              />
            );
          }

          if (
            type === 'private' &&
            !isAdmin &&
            uid &&
            !!restrictedTo &&
            restrictedTo !== propertyRoles[uid]
          ) {
            return (
              <AsyncDoesNotExist
                logout
                message="You don't have credentials to access this page."
                {...renderProps}
              />
            );
          }

          return <Component {...renderProps} />;
        }}
      />
    </>
  );
};

export default RestrictedRoute;
