import * as types from './types';

const defaultState = {};

export default function experienceLanguages(state = defaultState, action) {
  switch (action.type) {
    case types.ON_EXPERIENCE_LANGUAGE_CHANGED:
      return { ...state, [action.id]: action.value };
    default:
      return state;
  }
}
