import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Ellipsis = props => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0865 2.3365L14.1211 1.37109L12.6329 2.85928L3.42411 12.0681L1.58429 15.8387L5.41648 14.0605L16.1135 3.36347L15.0865 2.3365Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="12.4981"
        y1="2.9941"
        x2="14.4905"
        y2="4.98648"
        stroke="#333333"
      />
      <path d="M5.15444 14.3225C5.34971 14.5178 5.66629 14.5178 5.86155 14.3225C6.05681 14.1272 6.05681 13.8106 5.86155 13.6154L5.15444 14.3225ZM3.16207 12.3301L5.15444 14.3225L5.86155 13.6154L3.86918 11.623L3.16207 12.3301Z" />
    </SvgIcon>
  );
};

export default Ellipsis;
