export const ON_FILTER_OPEN_CHANGED = 'filters@ON_FILTER_OPEN_CHANGED';
export const ON_FILTER_SORT_FIELD_CHANGED =
  'filters@ON_FILTER_SORT_FIELD_CHANGED';
export const ON_FILTER_SORT_ORIENTATION_CHANGED =
  'filters@ON_FILTER_SORT_ORIENTATION_CHANGED';
export const ON_FILTER_FIELD_NAME_CHANGED =
  'filters@ON_FILTER_FIELD_NAME_CHANGED';
export const ON_FILTER_FIELD_VALUE_CHANGED =
  'filters@ON_FILTER_FIELD_VALUE_CHANGED';
export const ON_FILTER_SEARCH_TEXT_CHANGED =
  'filters@ON_FILTER_SEARCH_TEXT_CHANGED';
export const ON_ADD_FILTER_QUERY = 'filters@ON_ADD_FILTER_QUERY';
export const ON_EDIT_FILTER_QUERY = 'filters@ON_EDIT_FILTER_QUERY';
export const ON_REMOVE_FILTER_QUERY = 'filters@ON_REMOVE_FILTER_QUERY';
export const ON_SET_SEARCH = 'filters@ON_SET_SEARCH';
