/* eslint-disable react/prop-types */

import { Switch, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import Card from '../Card';
import { USER_FIELDS } from '../../constants';

const styles = () => ({
  switchRoot: {
    height: 'auto',
    marginTop: '-7px',
    marginRight: '-12px'
  },
  switchBase: {
    color: '#797979',
    '&$checked': {
      color: '#97023b'
    },
    '&$checked + $track': {
      backgroundColor: '#97023b'
    }
  },
  switchTrack: {
    height: '2px',
    marginTop: '7px'
  }
});

function ThemePanel(props) {
  const { classes, updateUser, user } = props;

  const [values, setValues] = useState({
    darkModeEnabled: user.darkModeEnabled || false
  });

  const handleCheckboxChange = async e => {
    const key = e.target.getAttribute('name');

    const data = { [key]: e.target.checked };

    setValues({
      ...values,
      [key]: e.target.checked
    });

    await updateUser(data);
  };

  return (
    <Card>
      <Grid
        container
        component="label"
        alignItems="center"
        spacing={2}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Card.Title>Dark Mode</Card.Title>
        </Grid>
        <Grid item>
          <Switch
            name={USER_FIELDS.DARK_MODE_ENABLED}
            color="primary"
            onChange={handleCheckboxChange}
            checked={!!values?.darkModeEnabled}
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              track: classes.switchTrack
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default withStyles(styles)(ThemePanel);
