/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SearchAnd = props => {
  return (
    <SvgIcon
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.0428 7.36353L11.0547 1.34388L1.04289 7.32421L11.0311 13.3439L21.0428 7.36353Z"
        fill="white"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
      <path
        d="M21.0311 13.3635L11.0429 7.34388L1.03117 13.3242L11.0194 19.3439L21.0311 13.3635Z"
        fill="white"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
    </SvgIcon>
  );
};

export default SearchAnd;
