import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageU = props => {
  return (
    <SvgIcon
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.34863 8.23827L11.0303 10.3246L15.7089 8.23827L11.0303 6.15193L6.34863 8.23827Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.6907 8.26735L15.7089 14.1922L11.0303 16.4616L6.34863 14.1922V8.22086"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.8564 15.247L13.3281 12.3354"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M2.19629 15.247L8.72454 12.3354"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.0022 1.3138C11.0087 3.90865 11.0185 8.20047 10.9956 8.20047"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.0849 16.3977C11.0653 16.3977 11.0849 10.345 11.0849 10.345"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeMiterlimit="10"
      />
      <path
        d="M8.96599 2.31455C8.76848 2.50754 8.76482 2.8241 8.95781 3.02161C9.15081 3.21912 9.46737 3.22277 9.66487 3.02978L8.96599 2.31455ZM11.0267 1L11.3761 0.642384L11.0267 0.300927L10.6773 0.642384L11.0267 1ZM12.3885 3.02978C12.586 3.22277 12.9026 3.21912 13.0956 3.02161C13.2886 2.8241 13.2849 2.50754 13.0874 2.31455L12.3885 3.02978ZM9.66487 3.02978L11.3761 1.35762L10.6773 0.642384L8.96599 2.31455L9.66487 3.02978ZM10.6773 1.35762L12.3885 3.02978L13.0874 2.31455L11.3761 0.642384L10.6773 1.35762Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
      />
      <path
        d="M19.9752 13.1142C19.8948 12.8528 19.6136 12.7074 19.3471 12.7893C19.0806 12.8712 18.9298 13.1495 19.0102 13.4109L19.9752 13.1142ZM20.1897 15.5272L20.3291 16.0023L20.8197 15.8582L20.6722 15.3788L20.1897 15.5272ZM17.7326 15.7331C17.4651 15.8117 17.3107 16.0881 17.3878 16.3505C17.4648 16.6129 17.7441 16.7619 18.0116 16.6833L17.7326 15.7331ZM19.0102 13.4109L19.7072 15.6755L20.6722 15.3788L19.9752 13.1142L19.0102 13.4109ZM20.0502 15.0521L17.7326 15.7331L18.0116 16.6833L20.3291 16.0023L20.0502 15.0521Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
      />
      <path
        d="M2.19186 13.0387C2.32216 12.7973 2.62609 12.7085 2.87071 12.8405C3.11533 12.9724 3.20802 13.2751 3.07773 13.5165L2.19186 13.0387ZM1.50588 15.3694L1.27846 15.811L0.822489 15.576L1.06295 15.1304L1.50588 15.3694ZM3.87398 16.0309C4.12104 16.1582 4.21951 16.4591 4.09391 16.703C3.9683 16.9469 3.6662 17.0414 3.41913 16.9141L3.87398 16.0309ZM3.07773 13.5165L1.94881 15.6083L1.06295 15.1304L2.19186 13.0387L3.07773 13.5165ZM1.7333 14.9277L3.87398 16.0309L3.41913 16.9141L1.27846 15.811L1.7333 14.9277Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
      />
    </SvgIcon>
  );
};

export default ImageU;
