import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Shuffle = props => {
  const { htmlColor } = props;

  return (
    <SvgIcon
      width="22"
      height="26"
      viewBox="0 0 24 28"
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M18 6H9C4.58172 6 1 9.58172 1 14"
        stroke={htmlColor || '#333'}
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M14 1L19 6L14 11"
        stroke={htmlColor || '#333'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 22L15 22C19.4183 22 23 18.4183 23 14"
        stroke={htmlColor || '#333'}
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M10 27L5 22L10 17"
        stroke={htmlColor || '#333'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Shuffle;
