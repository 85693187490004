import { getDatabase, ref, off, onValue } from 'firebase/database';
import * as types from './types';
import * as selectors from './selectors';
import * as initSelectors from '../initialization/selectors';
import { logError } from '../errors/actions';
import { logLoading } from '../loadings/actions';

export const valueChanged = (value, location, path) => {
  return {
    type: types.VALUE_CHANGED,
    payload: value,
    path,
    location,
    locationValue: true
  };
};

export const destroy = location => {
  return {
    type: types.DESTROY,
    location
  };
};

export const unWatch = path => {
  return {
    type: types.UNWATCH,
    path
  };
};

export function watchPath(
  firebaseApp,
  firebasePath,
  reduxPath = false,
  logLoad = true
) {
  const location = reduxPath || firebasePath;

  return (dispatch, getState) => {
    const isInitialized = initSelectors.isInitialised(getState(), location);

    if (!isInitialized) {
      const db = getDatabase(firebaseApp);
      const docRef = ref(db, firebasePath);
      const path = docRef.toString();

      if (logLoad) {
        dispatch(logLoading(location));
      }

      onValue(
        docRef,
        snapshot => {
          dispatch(valueChanged(snapshot.val(), location, path));
        },
        err => {
          dispatch(logError(location, err));
        }
      );
    }
  };
}

export function unwatchPath(firebaseApp, path, reduxPath = false) {
  return dispatch => {
    const db = getDatabase(firebaseApp);
    const docRef = ref(db, path);
    const location = reduxPath || path;
    off(docRef);
    dispatch(unWatch(location));
  };
}

export function destroyPath(firebaseApp, path, reduxPath = false) {
  const location = reduxPath || path;
  const db = getDatabase(firebaseApp);
  const docRef = ref(db, path);

  return dispatch => {
    off(docRef);
    dispatch(unWatch(location));
    dispatch(destroy(location));
  };
}

export function unwatchAllPaths(firebaseApp) {
  return (dispatch, getState) => {
    const allPaths = selectors.getAllPaths(getState());
    const db = getDatabase(firebaseApp);

    Object.keys(allPaths).forEach(function (key, index) {
      const docRef = ref(db, allPaths[index]);
      off(docRef);
      dispatch(unWatch(key));
    });
  };
}
