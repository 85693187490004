import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UrlIcon = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 9.82982 3.88179 8.05478 6.22409 8.05478H10.2822C10.5463 8.05478 10.7603 8.25104 10.7603 8.49314C10.7603 8.73523 10.5463 8.93149 10.2822 8.93149H6.22409C4.42876 8.93149 2.95633 10.2966 2.95633 12C2.95633 13.7034 4.42876 15.0685 6.22409 15.0685H10.2822C10.5463 15.0685 10.7603 15.2647 10.7603 15.5068C10.7603 15.7489 10.5463 15.9452 10.2822 15.9452H6.22409C3.88178 15.9452 2 14.1701 2 12Z"
        fill={htmlColor || '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4597 8.49315C13.4597 8.22079 13.6767 8 13.9444 8H17.8813C20.1316 8 22.0001 9.76662 22.0001 12C22.0001 14.2334 20.1316 16 17.8813 16H13.9444C13.6767 16 13.4597 15.7792 13.4597 15.5068C13.4597 15.2345 13.6767 15.0137 13.9444 15.0137H17.8813C19.6451 15.0137 21.0306 13.6402 21.0306 12C21.0306 10.3598 19.6451 8.9863 17.8813 8.9863H13.9444C13.6767 8.9863 13.4597 8.76551 13.4597 8.49315Z"
        fill={htmlColor || '#000'}
      />
      <line
        x1="8.39795"
        y1="12.1575"
        x2="15.9959"
        y2="12.1575"
        stroke={htmlColor || '#000'}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default UrlIcon;
