import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconPlay = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6V18L17.25 12L9 6Z"
        fill="white"
      />
      <circle cx="12" cy="12" r="11.5" stroke="white" />
    </SvgIcon>
  );
};

export default IconPlay;
