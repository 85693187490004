import { Typography, Avatar as MUIAvatar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getThumbnail from '../../../../../utils/getThumbnail';
import getSimpleValue from '../../../../../store/simpleValues/selectors';

const styles = () => ({
  avatar: {
    width: '96px',
    height: '96px',
    marginBottom: '20px',
    cursor: 'pointer'
  },
  avatarHeader: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    paddingBottom: '30px',
    position: 'relative',
    overflow: 'hidden',
    zIndex: '2',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-164.18%',
      backgroundColor: '#fff',
      borderRadius: '50%',
      width: '121.91%',
      height: '264.18%',
      zIndex: '-1'
    }
  },
  avatarHeaderHeadline: {
    fontWeight: '600',
    fontSize: '20px',
    marginBottom: '0.25em'
  },
  avatarHeaderSubheadline: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  photoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

function Header(props) {
  const { classes, user, values, onClick } = props;
  const [avatarURL, setAvatarURL] = useState('');

  const { photoThumbnails } = user;

  useEffect(() => {
    if (photoThumbnails) {
      setAvatarURL(getThumbnail('small', photoThumbnails));
    }
  }, [photoThumbnails]);

  const dName = user.name || user.displayName;

  const croppedImage = useSelector(state =>
    getSimpleValue(state, 'profile_cropped_image', '')
  );

  const userPhotoMarkup = (
    <div className={classes.photoWrapper}>
      <MUIAvatar
        className={classes.avatar}
        src={croppedImage || avatarURL}
        alt={dName}
        onClick={onClick}
      />
    </div>
  );

  const userFullNameMarkup = dName && (
    <Typography variant="h2" className={classes.avatarHeaderHeadline}>
      {dName}
    </Typography>
  );

  const userUIDMarkup = values.uid && (
    <Typography variant="body1" className={classes.avatarHeaderSubheadline}>
      {`User ID #${values.uid}`}
    </Typography>
  );

  return (
    <div className={classes.avatarHeader}>
      {userPhotoMarkup}
      {userFullNameMarkup}
      {userUIDMarkup}
    </div>
  );
}

export default withStyles(styles)(Header);
