import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const VideoU = props => {
  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6V16L16 11L8 6Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default VideoU;
