import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Ellipsis = props => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5684 11.0293L7.92195 11.3828L7.5684 11.7364L4.76167 14.5431L4.69535 14.6094L4.60949 14.6472L3.54634 15.115L2.36951 15.6328L2.88731 14.456L3.3551 13.3928L3.39288 13.3069L3.4592 13.2406L6.26593 10.4339L6.61948 10.0803L6.97303 10.4339L7.5684 11.0293Z"
        stroke="#333333"
      />
      <rect
        x="11.9766"
        y="3.53117"
        width="3.52592"
        height="5.20987"
        transform="rotate(45 11.9766 3.53117)"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M5.58606 6.66209C6.36368 6.19933 7.38448 6.30252 8.05364 6.97168L11.0305 9.94851C11.6996 10.6177 11.8028 11.6385 11.3401 12.4161L5.58606 6.66209Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M16.5858 5.97854C15.8082 6.4413 14.7874 6.3381 14.1182 5.66895L12.3321 3.88285C11.663 3.21369 11.5598 2.19289 12.0225 1.41528L16.5858 5.97854Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </SvgIcon>
  );
};

export default Ellipsis;
