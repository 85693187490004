import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Email = props => {
  return (
    <SvgIcon
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.35156"
        y="1"
        width="15"
        height="8"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
      <path
        d="M16.3516 1L8.63728 6L1.35156 1"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
      <path
        d="M16.3516 9L11.3516 4"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
      <path
        d="M1.35156 9L6.35156 4"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default Email;
