import * as actionTypes from './types';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import {
  getFilteredResults,
  getFilteredTags,
  getFilteredTypes,
  getQueryInTags,
  getQueryInTypes
} from '../../../utils/visualBuilder';

const initialState = {};
const presentationInitialState = {
  presentation: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const currentPresentationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_PRESENTATION: {
      const { presentation, propertyId } = action.payload;
      const {
        types,
        tags,
        typeTags,
        tagTypes,
        primaryFilter,
        typeFilter,
        tagFilter,
        mediaLibTags = [],
        tagsOrdered = [],
        contentsOrdered = {},
        search = {},
        mediaLibHiddenTags = []
      } = presentation;

      let {
        leftDrawerAutoHide = false,
        rightDrawerAutoHide = false,
        leftDrawer = true,
        rightDrawer = true
      } = presentation;

      const queryInTypes = getQueryInTypes({ types });
      const queryInTags = getQueryInTags({ tags });

      const filteredTypes = getFilteredTypes({
        queryInTypes,
        primaryFilter,
        tagFilter,
        search
      });
      const filteredTags = getFilteredTags({
        queryInTags,
        primaryFilter,
        typeFilter,
        search,
        mediaLibHiddenTags
      });

      const filteredResults = getFilteredResults({
        primaryFilter,
        typeFilter,
        tagFilter,
        queryInTypes,
        queryInTags,
        types,
        tags,
        typeTags,
        tagTypes,
        mediaLibTags,
        tagsOrdered,
        contentsOrdered,
        search
      });

      if (window.innerWidth < 720) {
        leftDrawer = false;
        rightDrawer = false;
      }

      return {
        ...state,
        [propertyId]: {
          ...action.payload,
          presentation: {
            ...presentation,
            filteredResults,
            queryInTypes,
            queryInTags,
            filteredTypes,
            filteredTags,
            leftDrawerAutoHide,
            rightDrawerAutoHide,
            leftDrawer,
            rightDrawer
          }
        }
      };
    }
    case actionTypes.REFRESH_CURRENT_PRESENTATION: {
      const { presentation: newPresentation, propertyId } = action.payload;
      const { presentation: statePresentation } = state[propertyId];
      const presentation = { ...statePresentation, ...newPresentation };
      const {
        primaryFilter,
        typeFilter,
        tagFilter,
        types = {},
        tags = {},
        typeTags = {},
        tagTypes = {},
        mediaLibTags = [],
        tagsOrdered = [],
        contentsOrdered = {},
        search = {},
        mediaLibHiddenTags = []
      } = presentation;

      let {
        leftDrawerAutoHide = false,
        rightDrawerAutoHide = false,
        leftDrawer = true,
        rightDrawer = true
      } = presentation;


      const queryInTypes = getQueryInTypes({ types });
      const queryInTags = getQueryInTags({ tags });

      const filteredTypes = getFilteredTypes({
        queryInTypes,
        primaryFilter,
        tagFilter,
        search
      });
      const filteredTags = getFilteredTags({
        queryInTags,
        primaryFilter,
        typeFilter,
        search,
        mediaLibHiddenTags
      });

      const filteredResults = getFilteredResults({
        primaryFilter,
        typeFilter,
        tagFilter,
        queryInTypes,
        queryInTags,
        types,
        tags,
        typeTags,
        tagTypes,
        mediaLibTags,
        tagsOrdered,
        contentsOrdered,
        search
      });

      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          presentation: {
            ...presentation,
            filteredResults,
            queryInTypes,
            queryInTags,
            filteredTypes,
            filteredTags,
            leftDrawerAutoHide,
            rightDrawerAutoHide,
            leftDrawer,
            rightDrawer
          }
        }
      };
    }
    case actionTypes.RESET_CURRENT_PRESENTATION_STATUS: {
      const { propertyId } = action.payload;
      if (propertyId && state[propertyId]) {
        return {
          ...state,
          [propertyId]: {
            ...state[propertyId],
            status: REQUEST_STATUSES.IDLE
          }
        };
      }
      return state;
    }
    case actionTypes.SET_CURRENT_PRESENTATION_REQUEST_STATUS: {
      const { propertyId } = action.payload;
      return {
        ...state,
        [propertyId]: {
          ...presentationInitialState,
          ...state[propertyId],
          ...action.payload
        }
      };
    }
    case actionTypes.RESET_CURRENT_PRESENTATION: {
      const { propertyId = null } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: presentationInitialState };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
