import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import pMinDelay from 'p-min-delay';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { toast } from 'react-toastify';
import LoadingComponent from './containers/LoadingComponent';
import { wdyr, currentEnv } from './config/envSettings';

if (wdyr()) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    trackExtraHooks: [[require('react-redux/lib'), 'useSelector']]
  });
}

const loadWithTimeout = import('./App');
const loadWithDelay = pMinDelay(loadWithTimeout, 1000);

const AppAsync = loadable(() => loadWithDelay, {
  fallback: LoadingComponent.Splash({
    pastDelay: true,
    error: false,
    timedOut: false
  })
});

const reactapp = document.createElement('div');
reactapp.style.height = '100%';

document.body.appendChild(reactapp);
const root = createRoot(reactapp);
root.render(<AppAsync />);

const env = currentEnv();

if (env === 'qa' || env === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_IO_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: reg => {
//     if (reg && reg.waiting) {
//       try {
//         console.log('postMessage SKIP_WAITING');
//         reg.waiting.postMessage({ type: 'SKIP_WAITING' });
//         setTimeout(() => {
//           toast.info('Update Available. Click to Refresh.', {
//             onClick: () => window.location.reload()
//           });
//         }, 3000);
//       } catch (error) {
//         console.warn('error', error);
//       }
//     }
//   }
// });

export { AppAsync as default };
