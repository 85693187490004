import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  contactEvents: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const contactEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTACT_EVENTS: {
      const { contactEvents, status, error, query } = action.payload;
      const newContactEvents = getNewList(
        query,
        state.contactEvents,
        contactEvents
      );
      const indexes = getListIndexes(newContactEvents);
      return {
        ...state,
        contactEvents: newContactEvents,
        status,
        error,
        indexes
      };
    }
    case types.REFRESH_CONTACT_EVENTS: {
      const { contactEventId, contactEvent } = action.payload;
      const newContactEvents = [...state.contactEvents];

      newContactEvents[state.indexes[contactEventId]] = contactEvent;

      const indexes = getListIndexes(newContactEvents);

      return { ...state, contactEvents: newContactEvents, indexes };
    }
    case types.SET_CONTACT_EVENTS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CONTACT_EVENTS:
      return { ...initialState };
    default:
      return state;
  }
};
