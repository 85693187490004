import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as types from './types';

export function userLogout() {
  return {
    type: types.USER_LOGOUT
  };
}

export function authStateChanged(user) {
  return {
    type: types.AUTH_STATE_CHANGED,
    payload: user
  };
}

export function authError(error) {
  return {
    type: types.AUTH_ERROR,
    payload: error
  };
}

export function defaultUserData(user) {
  if (user != null) {
    return {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      providerData: user.providerData,
      isAuthorised: true
    };
  }
  return {
    isAuthorised: false
  };
}

export function watchAuth(firebaseApp, calbackFn, errorFn) {
  const auth = getAuth(firebaseApp);

  return dispatch => {
    const onComplete = user => {
      if (calbackFn && calbackFn instanceof Function) {
        dispatch(
          authStateChanged({
            isAuthorised: !!user,
            ...calbackFn(user)
          })
        );
      } else {
        dispatch(
          authStateChanged({ isAuthorised: !!user, ...defaultUserData(user) })
        );
      }
    };

    const onError = error => {
      if (errorFn && errorFn instanceof Function) {
        dispatch(authError(error));
      } else {
        dispatch(authError(error));
      }
    };

    return onAuthStateChanged(auth, onComplete, onError);
  };
}
