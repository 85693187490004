import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DeleteGrey = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon
      fontSize="small"
      width={10}
      height={8}
      viewBox="0 0 10 8"
      {...props}
    >
      <g
        id="Symbols"
        stroke={htmlColor || 'none'}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeOpacity={htmlColor ? '1' : '0.5'}
      >
        <g
          id="Icon/Delete/Small/Grey"
          transform="translate(1.000000, 0.000000)"
          stroke={htmlColor || '#000'}
        >
          <g id="Group-2">
            <line
              x1="0"
              y1="-9.09494702e-13"
              x2="7.90225397"
              y2="7.90225397"
              id="Path-9"
            />
            <line
              x1="0"
              y1="2.72848411e-12"
              x2="7.90225397"
              y2="7.90225397"
              id="Path-9"
              transform="translate(3.951127, 3.951127) rotate(-90.000000) translate(-3.951127, -3.951127) "
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DeleteGrey;
