/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import LoadingSplash from './LoadingSplash';
import ErrorBoundary from '../ErrorBoundary';
import config from '../../config';
import getThemeSource, { themes } from '../../config/themes';

const defaultTheme = getThemeSource(config.initial_state.themeSource, themes);
const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  loader: {
    height: '80px'
  }
});

export const LoadingComponent = props => {
  // console.log('PRELOADING');
  if (props.isLoading) {
    // While our other component is loading...
    if (props.timedOut) {
      // In case we've timed out loading our other component.
      return <div>Loader timed out!</div>;
    }
    if (props.pastDelay) {
      // Display a loading screen after a set delay. <img src="/logo.svg" className="loader" alt="logo" />
      return (
        <div className={props.classes.container}>
          {/* <img
            src={`${process.env.PUBLIC_URL}/rmw.svg`}
            className={props.classes.loader}
            alt="logo"
          /> */}
        </div>
      );
    }
    // Don't flash "Loading..." when we don't need to.
    return null;
  }
  // if (props.error) {
  //   console.warn(props.error);

  //   // Reload page on first failed load
  //   if (window.location.href.indexOf('isReload') === -1) {
  //     window.location.href = `${window.location.href}?isReload=1`;
  //   } else {
  //     window.location.href = `${window.location.href}&isReload=1`;
  //   }

  //   // If we aren't loading, maybe
  //   return <div>Error! Component failed to load</div>;
  // }
  // This case shouldn't happen... but we'll return null anyways.
  return null;
};

const withStylesOptions = { defaultTheme, withTheme: true };

const Component = compose(withStyles(styles, withStylesOptions))(
  LoadingComponent
);

Component.Fallback = props => (
  <ErrorBoundary>
    <Component {...props} />
  </ErrorBoundary>
);

Component.Splash = props => (
  <ErrorBoundary>
    <LoadingSplash {...props} />
  </ErrorBoundary>
);

export default Component;
