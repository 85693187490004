import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  messages: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MESSAGES: {
      const { messages, status, error, query } = action.payload;
      const newMessages = getNewList(query, state.messages, messages);
      const indexes = getListIndexes(newMessages);
      return {
        ...state,
        messages: newMessages,
        status,
        error,
        indexes
      };
    }
    case types.REFRESH_MESSAGES: {
      const { messageId, message } = action.payload;
      const newMessages = [...state.messages];

      newMessages[state.indexes[messageId]] = message;

      const indexes = getListIndexes(newMessages);

      return { ...state, messages: newMessages, indexes };
    }
    case types.SET_MESSAGES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_MESSAGES:
      return { ...initialState };
    default:
      return state;
  }
};
