import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const MapPointIcon = props => {
  const { color = '#333333' } = props;
  return (
    <SvgIcon width="14" height="20" viewBox="0 0 14 20" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M13 7.19369C13 8.31261 12.6826 9.35757 12.1315 10.2507L7.00078 18.7L1.88334 10.2719L1.77753 10.0964C1.28217 9.24033 1 8.24966 1 7.19369C1 3.93684 3.68609 1.30005 7.00078 1.30005C10.3147 1.30005 13 3.93684 13 7.19369Z"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M9 7.39011C9 8.5109 8.10484 9.42011 7.00039 9.42011C5.89594 9.42011 5 8.5109 5 7.39011C5 6.26931 5.89594 5.36011 7.00039 5.36011C8.10484 5.36011 9 6.26931 9 7.39011Z"
        fill="none"
        fillRule="evenodd"
        stroke={color}
      />
    </SvgIcon>
  );
};

export default MapPointIcon;
