/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */

import { red, pink, grey } from '@mui/material/colors';

export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    lg: 1200,
    md: 900,
    sm: 600,
    xl: 1536
  }
};

export const themes = [
  {
    id: 'default',
    color: '#8f0339',
    source: {
      typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(',')
        // body1: {
        //   fontSize: '14px'
        // }
      },
      palette: {
        primary: {
          main: '#8f0339'
        },
        secondary: {
          light: '#fff',
          main: grey[50],
          dark: grey[500]
        },
        text: {
          main: 'rgba(0, 0, 0, 0.87)'
        },
        info: {
          main: '#6dd400',
          contrastText: '#fff'
        },
        error: red,
        background: {
          default: grey[100]
        }
      },
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&.MuiTableRow-hover': {
                '&:hover': {
                  backgroundColor: '#fff'
                }
              }
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '0',
              boxShadow: 'none !important',
              textTransform: 'initial'
            }
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: '0'
            }
          }
        },
        MuiInput: {
          styleOverrides: {
            input: {
              backgroundColor: grey[100],
              borderRadius: 0,
              padding: '8px'
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: '2px',
              width: '100%'
            },
            input: {
              backgroundColor: grey[100]
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none'
            }
          }
        },
        MuiFilledInput: {
          styleOverrides: {
            input: {
              borderRadius: 0,
              paddingTop: '10px'
            },
            root: {
              backgroundColor: grey[100]
            }
          }
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              backgroundColor: grey[100]
            }
          }
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 0
            }
          }
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              color: '#8f0339'
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            h6: {
              color: '#8f0339'
            }
          }
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: 'none'
            }
          }
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              color: '#8f0339',
              minHeight: '48px !important'
            },
            content: {
              margin: '12px 0 !important'
            }
          }
        }
      }
    }
  },
  {
    id: 'red',
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red
      }
    }
  },
  {
    id: 'green',
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: red,
        error: red
      }
    }
  }
];

const getThemeSource = (t, ts) => {
  if (ts) {
    for (let i = 0; i < ts.length; i++) {
      if (ts[i].id === t.source) {
        const source = ts[i].source;
        const palette = source != null ? source.palette : {};
        const typography = source != null ? source.typography : {};

        return {
          ...source,
          breakpoints,
          typography: { ...typography },
          palette: { ...palette, mode: t.isNightModeOn ? 'dark' : 'light' }
        };
      }
    }
  }

  return {
    breakpoints,
    typography: { useNextVariants: true },
    palette: { mode: t.isNightModeOn ? 'dark' : 'light' }
  }; // Default theme
};

export default getThemeSource;
