import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  contacts: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTACTS: {
      const { contacts, status, error, query } = action.payload;
      const newContacts = getNewList(query, state.contacts, contacts);
      const indexes = getListIndexes(newContacts);
      let noResults = false;
      if (newContacts.length === 0) noResults = true;
      return {
        ...state,
        contacts: newContacts,
        status,
        error,
        indexes,
        noResults
      };
    }
    case types.REFRESH_CONTACTS: {
      const { contactId, contact } = action.payload;
      const newContacts = [...state.contacts];

      newContacts[state.indexes[contactId]] = contact;

      return { ...state, contacts: newContacts };
    }
    case types.SET_CONTACTS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CONTACTS:
      return { ...initialState };
    default:
      return state;
  }
};
