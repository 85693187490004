import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import Card from '../Card';
import { USER_FIELDS } from '../../constants';
import PhoneNumber from '../../../../components/PhoneNumberInput/PhoneNumberInput';

const styles = () => ({
  formControlRoot: {
    marginBottom: '30px',
    '&:last-child': {
      marginBottom: 0
    }
  },
  textFieldInput: {
    backgroundColor: 'transparent'
    // padding: '12px 16px'
  },
  textFieldLabel: {
    color: '#000'
  }
});

function InfoPanel(props) {
  const {
    classes,
    values,
    errors,
    info,
    onBlur,
    initialEmail,
    onChange,
    hidePassword = false,
    defaultValues = {}
  } = props;

  function renderPhoneInput(options) {
    const { id, label, placeholder, name } = options;

    return (
      <PhoneNumber
        id={id}
        name={name}
        value={values[name] || ''}
        defaultValue={defaultValues[name] || ''}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        style={{ marginBottom: '25px' }}
        errors
        onBlur={onBlur}
      />
    );
  }

  function renderTextField(options) {
    const { id, label, placeholder, name, type = 'text' } = options;

    return (
      <TextField
        fullWidth
        variant="outlined"
        size="medium"
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        label={label}
        placeholder={placeholder}
        helperText={errors[name] || info[name]}
        value={values[name] || ''}
        error={!!errors[name]}
        name={name}
        type={type}
        classes={{ root: classes.formControlRoot }}
        InputProps={{
          classes: { input: classes.textFieldInput }
        }}
        InputLabelProps={{
          classes: { root: classes.textFieldLabel }
        }}
      />
    );
  }

  return (
    <Card>
      <Card.Title>Info</Card.Title>
      <Card.Body>
        {renderTextField({
          id: 'Name',
          label: 'Name',
          placeholder: 'Name',
          type: 'text',
          name: USER_FIELDS.NAME
        })}
        {renderPhoneInput({
          id: 'WorkPhone',
          label: 'Work Phone',
          placeholder: '###-###-#### x####',
          type: 'tel',
          name: USER_FIELDS.WORK_PHONE
        })}
        {renderPhoneInput({
          id: 'CellPhone',
          label: 'Cell Phone',
          placeholder: '###-###-#### x####',
          type: 'tel',
          name: USER_FIELDS.CELL_PHONE
        })}
        {renderTextField({
          id: 'Email',
          label: 'Email',
          placeholder: 'Email Address',
          type: 'email',
          name: USER_FIELDS.EMAIL
        })}
        {!hidePassword &&
          initialEmail.toUpperCase() !== values.email.toUpperCase() &&
          renderTextField({
            id: 'Password',
            label: 'Password',
            placeholder: 'Password',
            type: 'password',
            name: USER_FIELDS.PASSWORD
          })}
      </Card.Body>
    </Card>
  );
}

export default withStyles(styles)(InfoPanel);