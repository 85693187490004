import { REQUEST_STATUSES } from '../constants/requestStatuses';

export const getListIndexes = list =>
  list.reduce((acc, el, index) => ({ ...acc, [el.id]: index }), {});

export const getNewList = (query, list, newList) =>
  !query.offset ? newList : [...list, ...newList];

const allowedStatuses = [REQUEST_STATUSES.IDLE, REQUEST_STATUSES.FAILED];

export const isRequestAllowed = status => allowedStatuses.indexOf(status) > -1;

export const inArray = (arr = [], value = '') => arr.indexOf(value) > -1;
