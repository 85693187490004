/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { Content, Engagement, General } from './components';
import { Card } from '..';

function NotificationPanel(props) {
  const { email, user, updateUser } = props;

  const [values, setValues] = useState({
    generalNotificationsSMSEnabled:
      user.generalNotificationsSMSEnabled || false,
    generalNotificationsEmailEnabled:
      user.generalNotificationsEmailEnabled || false,
    generalNotificationsPushEnabled:
      user.generalNotificationsPushEnabled || false,
    contentNotificationsSMSEnabled:
      user.contentNotificationsSMSEnabled || false,
    contentNotificationsEmailEnabled:
      user.contentNotificationsEmailEnabled || false,
    contentNotificationsPushEnabled:
      user.contentNotificationsPushEnabled || false,
    engagementNotificationsSMSEnabled:
      user.engagementNotificationsSMSEnabled || false,
    engagementNotificationsEmailEnabled:
      user.engagementNotificationsEmailEnabled || false,
    engagementNotificationsPushEnabled:
      user.engagementNotificationsPushEnabled || false
  });

  const handleCheckboxChange = async e => {
    const key = e.target.getAttribute('name');

    const data = { [key]: e.target.checked };

    setValues({
      ...values,
      [key]: e.target.checked
    });

    await updateUser(data);
  };

  return (
    <Card>
      <Card.Title>Notifications</Card.Title>
      <Card.Body style={{ marginTop: '15px' }}>
        <General
          email={email}
          values={values}
          onChange={handleCheckboxChange}
        />
        <Content
          email={email}
          values={values}
          onChange={handleCheckboxChange}
        />
        <Engagement
          email={email}
          values={values}
          onChange={handleCheckboxChange}
        />
      </Card.Body>
    </Card>
  );
}

export default NotificationPanel;
