import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageU = props => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14L8.89615 8L11.1215 11L13.0107 9.55801L17 14H3Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1 8.42857C14.0573 8.42857 14.8334 7.66106 14.8334 6.71428C14.8334 5.76751 14.0573 5 13.1 5C12.1427 5 11.3667 5.76751 11.3667 6.71428C11.3667 7.66106 12.1427 8.42857 13.1 8.42857Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
      />
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="2"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ImageU;
