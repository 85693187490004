import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RemoveData = props => {
  return (
    <SvgIcon
      width="54"
      height="56"
      viewBox="0 0 54 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 35V50"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
      />
      <path
        d="M30 35L28 50"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0434 29.7729C36.4582 29.1552 36.4582 28.1878 37.0434 27.5702L50.6315 13.2271C51.2632 12.5604 52.325 12.5604 52.9566 13.2271V13.2271C53.5418 13.8448 53.5418 14.8122 52.9566 15.4298L39.3685 29.7729C38.7368 30.4396 37.675 30.4396 37.0434 29.7729V29.7729Z"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
      />
      <path
        d="M48.2856 21L48.5857 21.3001C49.3668 22.0811 49.3668 23.3475 48.5857 24.1285L47.1285 25.5858C46.3474 26.3668 45.0811 26.3668 44.3 25.5858L44 25.2857"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
      />
      <path
        d="M23.8029 54.9775L31.2128 54.9976C31.7116 54.999 32.1352 54.6325 32.2055 54.1386L35.3375 32.141C35.4233 31.5387 34.9559 31 34.3475 31H23.8029H24.1971H13.6525C13.0441 31 12.5767 31.5387 12.6625 32.141L15.7945 54.1386C15.8648 54.6325 16.2884 54.999 16.7872 54.9976L24.1971 54.9775"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 35L20 50"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
      />
      <path
        d="M0.978132 15.6113L14.3258 1.29764C14.7025 0.893723 15.3353 0.871625 15.7392 1.24828L22.2868 7.35408C22.6908 7.73074 22.7128 8.36352 22.3362 8.76744L21.9223 9.21125C21.5457 9.61516 21.5678 10.2479 21.9717 10.6246L32.5586 20.497C32.9625 20.8737 32.9846 21.5065 32.6079 21.9104L31.4933 23.1057L-nan -nanL31.4933 23.1057"
        stroke={props.htmlColor || '#8F0339'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 31L1.86179 16.3096C1.41093 15.886 1.44888 15.1589 1.94138 14.7845L12.8306 6.50875C13.2176 6.21465 13.76 6.24139 14.1161 6.57212L33.6131 24.6765C34.0733 25.1037 34.0304 25.8444 33.5241 26.2157L27 31"
        stroke={props.htmlColor || '#8F0339'}
      />
    </SvgIcon>
  );
};

export default RemoveData;
