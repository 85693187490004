import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  mediaLibs: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const mediaLibsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIA_LIBS: {
      const { mediaLibs, status, error, query } = action.payload;
      const newMediaLibs = getNewList(query, state.mediaLibs, mediaLibs);
      const indexes = getListIndexes(newMediaLibs);
      return {
        ...state,
        mediaLibs: newMediaLibs,
        status,
        error,
        indexes,
        noResults: !newMediaLibs.length
      };
    }
    case types.REFRESH_MEDIA_LIBS: {
      const { mediaLibId, mediaLib } = action.payload;
      const newMediaLibs = [...state.mediaLibs];

      newMediaLibs[state.indexes[mediaLibId]] = mediaLib;

      return { ...state, mediaLibs: newMediaLibs };
    }
    case types.SET_MEDIA_LIBS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_MEDIA_LIBS:
      return { ...initialState };
    default:
      return state;
  }
};
