import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ExpMenu = props => {
  return (
    <SvgIcon
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1.6427H19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 8.224H19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 14.8053H19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ExpMenu;
