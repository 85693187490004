import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS: {
      const { notifications, status, error, query } = action.payload;
      const { category } = query;
      const { notification: stateNotifications = [] } = state[category] || {};
      const newNotifications = getNewList(
        query,
        stateNotifications,
        notifications
      );
      const indexes = getListIndexes(newNotifications);
      return {
        ...state,
        [category]: {
          notifications: newNotifications,
          status,
          error,
          indexes,
          noResults: !newNotifications.length
        }
      };
    }
    case types.REFRESH_NOTIFICATIONS: {
      const { notificationId, notification } = action.payload;
      const { category } = notification.event;
      const { notifications, indexes } = state[category];
      const newNotifications = [...notifications];

      newNotifications[indexes[notificationId]] = notification;

      return {
        ...state,
        [category]: {
          ...state[category],
          notifications: newNotifications
        }
      };
    }
    case types.GET_NOTIFICATIONS_OPTIONS:
      return { ...state, ...action.payload };
    case types.SET_NOTIFICATIONS_REQUEST_STATUS: {
      const { category } = action.payload;
      return { ...state, [category]: { ...(state[category] || {}), ...action.payload } };
    }
    case types.RESET_NOTIFICATIONS:
      return { ...initialState };
    default:
      return state;
  }
};
