/* eslint-disable react/prop-types */

import { Grid } from '@mui/material';
import React from 'react';
import { Card, Button } from '..';

function ResetPasswordPanel(props) {
  const { onClick } = props;

  return (
    <Card>
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Card.Title>Reset Password</Card.Title>
        </Grid>
        <Grid item>
          <Button onClick={onClick}>reset</Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ResetPasswordPanel;
