/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SearchOr = props => {
  return (
    <SvgIcon
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.3203 7.22461L11.3203 1.22461L1.32031 7.22461L11.3203 13.2246L21.3203 7.22461Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
      <path
        d="M21.3203 13.2246L11.3203 7.22461L1.32031 13.2246L11.3203 19.2246L21.3203 13.2246Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
      <path
        d="M16.3203 10.2246L11.3203 7.22461L6.32031 10.2246L11.3203 13.2246L16.3203 10.2246Z"
        fill="white"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
    </SvgIcon>
  );
};

export default SearchOr;
