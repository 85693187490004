/* eslint-disable react/prop-types */

import React from 'react';
import { Accordion, FormControlLabel } from '..';
import { BellEmpty } from '../../../../../../../icons';
import { USER_FIELDS } from '../../../../constants';

function General(props) {
  const { email, values, onChange } = props;

  const iconMarkup = (
    <BellEmpty read="true" htmlColor="#000" style={{ fontSize: '17px' }} />
  );

  return (
    <Accordion
      icon={iconMarkup}
      label="General Notifications"
      disclosure="General notifications notify you of new features, administartive updates, and contact updates"
    >
      <FormControlLabel.Phone
        name={USER_FIELDS.GENERAL_NOTIFICATIONS_SMS_ENABLED}
        values={values}
        onChange={onChange}
      />

      <FormControlLabel.Email
        name={USER_FIELDS.GENERAL_NOTIFICATIONS_EMAIL_ENABLED}
        values={values}
        onChange={onChange}
        email={email}
      />

      <FormControlLabel.Push
        name={USER_FIELDS.GENERAL_NOTIFICATIONS_PUSH_ENABLED}
        values={values}
        onChange={onChange}
      />
    </Accordion>
  );
}

export default General;
