import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Contact = props => {
  return (
    <SvgIcon
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0406 10.2401L12.0406 13.2966L1.41992 13.2966L1.41992 10.2401C1.41992 8.4524 2.61903 6.94147 4.28637 6.48012C3.61258 5.84461 3.23572 4.9692 3.23572 4.01189C3.23572 2.10882 4.80027 0.551758 6.73141 0.551758C7.70097 0.551758 8.5689 0.942753 9.19701 1.56558C9.83653 2.18955 10.2248 3.06612 10.2248 4.01189C10.2248 4.9692 9.84795 5.84461 9.18559 6.48012C9.90505 6.68773 10.5446 7.09141 11.0356 7.62081C11.6637 8.31399 12.0406 9.23669 12.0406 10.2401Z"
        stroke={props.htmlColor || '#fff'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default Contact;
