import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementStar = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        d="M12 17.9252L6.43769 21L7.5 14.4875L3 9.87539L9.21885 8.92523L12 3L14.7812 8.92523L21 9.87539L16.5 14.4875L17.5623 21L12 17.9252Z"
        stroke="white"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ElementStar;
