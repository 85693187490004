import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  users: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS: {
      const { users, status, error, query } = action.payload;
      const newUsers = getNewList(query, state.users, users);
      const indexes = getListIndexes(newUsers);
      return {
        ...state,
        users: newUsers,
        status,
        error,
        indexes,
        noResults: !newUsers.length
      };
    }
    case types.REFRESH_USERS: {
      const { userId, user } = action.payload;
      const newUsers = [...state.users];

      newUsers[state.indexes[userId]] = user;

      return { ...state, users: newUsers };
    }
    case types.SET_USERS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_USERS:
      return { ...initialState };
    default:
      return state;
  }
};
