import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  share: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const currentShareReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_SHARE: {
      const share = { ...state.share };
      const newShare = { ...action.payload.share };
      return { ...state, ...action.payload, share: { ...share, ...newShare } };
    }
    case types.SET_CURRENT_SHARE: {
      const share = { ...state.share };
      const newShare = { ...action.payload.share };
      return { ...state, ...action.payload, share: { ...share, ...newShare } };
    }
    case types.REFRESH_CURRENT_SHARE: {
      const share = { ...state.share };
      const newShare = { ...action.payload.share };
      return { ...state, ...action.payload, share: { ...share, ...newShare } };
    }
    case types.SET_CURRENT_SHARE_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_SHARE:
      return { ...initialState };
    default:
      return state;
  }
};
