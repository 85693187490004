const ContentTypes = [
  { id: 1, name: '360 Photo', hide: false, value: '360 Photo' },
  { id: 2, name: '3D Model', hide: false, value: '3D Model' },
  { id: 3, name: 'Video', hide: false, value: 'Video' },
  { id: 4, name: 'Photo', hide: false, value: 'Photo' },
  { id: 5, name: 'PDF', hide: false, value: 'PDF' },
  { id: 6, name: 'Link', hide: false, value: 'Link' },
  { id: 7, name: 'Audio', hide: false, value: 'Audio' }
];

export default ContentTypes;
