/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UniversityIcon = props => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 10L11.7907 13.7674L3 10"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17.5 18L12 20L6.5 18"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21 17L21 10"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M17.5 18L17.5 12M6.5 18L6.5 12"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M3 10L12.7674 6.27907L21 10"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default UniversityIcon;
