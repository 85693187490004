/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Search = props => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.97006 10.4148C8.71495 10.4148 10.9401 8.23081 10.9401 5.53674C10.9401 2.84267 8.71495 0.658691 5.97006 0.658691C3.22517 0.658691 1 2.84267 1 5.53674C1 8.23081 3.22517 10.4148 5.97006 10.4148Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeOpacity={props.strokeOpacity || '0.5'}
        fill="none"
      />
      <path
        d="M8.85547 9.43896L12.8315 13.3414"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        strokeOpacity={props.strokeOpacity || '0.5'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default Search;
