import * as types from './types';

const defaultState = {};

export default function documentsSelects(state = defaultState, action) {
  switch (action.type) {
    case types.ON_DOCUMENTS_SELECT_MODE:
      return { ...state, [action.id]: action.value };
    case types.ON_DOCUMENTS_UNSELECT:
      return {
        ...state,
        [action.id]: { selectMode: false, selectAll: false, selected: {} }
      };
    default:
      return state;
  }
}
