import { canUseWebP } from './canUseWebP';

function getThumbnail(size = 'tiny', photoThumbnails = {}, photoURL = '') {
  let photo = photoURL;
  if (
    photoThumbnails &&
    photoThumbnails.thumbnails &&
    photoThumbnails.thumbnails.full
  ) {
    const { thumbnails, version } = photoThumbnails;
    const { full } = thumbnails;
    const thumbnailSize = thumbnails[size];
    if (canUseWebP() && full && full.webp) {
      if (thumbnailSize) {
        photo = thumbnailSize.webp;
      } else {
        photo = full.webp;
      }
    } else if (thumbnailSize) {
      photo = thumbnailSize.jpg;
    } else {
      photo = full.jpg;
    }
    if (version) {
      photo = `${photo}?v=${version}`;
    }
  }
  return photo;
}
export default getThumbnail;
