import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InfoHelp = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7152 14.6387V14.3223C12.7152 13.8652 12.7855 13.4902 12.9262 13.1973C13.0668 12.9004 13.3285 12.5879 13.7113 12.2598C14.2426 11.8105 14.5766 11.4727 14.7133 11.2461C14.8539 11.0195 14.9242 10.7461 14.9242 10.4258C14.9242 10.0273 14.7953 9.7207 14.5375 9.50586C14.2836 9.28711 13.9164 9.17773 13.4359 9.17773C13.1273 9.17773 12.8266 9.21484 12.5336 9.28906C12.2406 9.35938 11.9047 9.49023 11.5258 9.68164L11.1801 8.89062C11.9184 8.50391 12.6898 8.31055 13.4945 8.31055C14.2406 8.31055 14.8207 8.49414 15.2348 8.86133C15.6488 9.22852 15.8559 9.74609 15.8559 10.4141C15.8559 10.6992 15.8168 10.9512 15.7387 11.1699C15.6645 11.3848 15.5531 11.5898 15.4047 11.7852C15.2563 11.9766 14.9359 12.2871 14.4438 12.7168C14.0492 13.0527 13.7875 13.332 13.6586 13.5547C13.5336 13.7773 13.4711 14.0742 13.4711 14.4453V14.6387H12.7152ZM12.4281 16.3789C12.4281 15.8477 12.6625 15.582 13.1313 15.582C13.3578 15.582 13.5316 15.6504 13.6527 15.7871C13.7777 15.9238 13.8402 16.1211 13.8402 16.3789C13.8402 16.6289 13.7777 16.8242 13.6527 16.9648C13.5277 17.1016 13.3539 17.1699 13.1313 17.1699C12.9281 17.1699 12.7602 17.1094 12.6273 16.9883C12.4945 16.8633 12.4281 16.6602 12.4281 16.3789Z"
        fill={props.htmlColor ? props.htmlColor : '#8F0339'}
      />
      <circle
        cx="13.1997"
        cy="12.4"
        r="8.5"
        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default InfoHelp;
