import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  mediaItems: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const mediaItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIA_ITEMS: {
      const { mediaItems, status, error, query } = action.payload;
      const newMediaItems = getNewList(query, state.mediaItems, mediaItems);
      const indexes = getListIndexes(newMediaItems);
      return {
        ...state,
        mediaItems: newMediaItems,
        status,
        error,
        indexes,
        noResults: !newMediaItems.length
      };
    }
    case types.REFRESH_MEDIA_ITEMS: {
      const { mediaItemId, mediaItem } = action.payload;
      const newMediaItems = [...state.mediaItems];

      newMediaItems[state.indexes[mediaItemId]] = mediaItem;

      return { ...state, mediaItems: newMediaItems };
    }
    case types.SET_MEDIA_ITEMS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_MEDIA_ITEMS:
      return { ...initialState };
    default:
      return state;
  }
};
