/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import loadable from '@loadable/component';
import { ReactReduxContext } from 'react-redux';
import FirebaseProvider from '../../contexts/FirekitProvider';
import ErrorBoundary from '../ErrorBoundary';

export default function makeLoadable(opts, preloadComponents) {
  const { loader, firebase } = opts;
  const ComponentAsync = loadable(() => loader());
  const Firebase = loadable.lib(firebase);

  const Component = props => {
    if (Array.isArray(preloadComponents)) {
      preloadComponents.map(component => component.preload());
    }

    return (
      <Firebase>
        {({ firebaseApp }) => (
          <FirebaseProvider
            firebaseApp={firebaseApp}
            context={ReactReduxContext}
          >
            <ErrorBoundary>
              <ComponentAsync {...props} />
            </ErrorBoundary>
          </FirebaseProvider>
        )}
      </Firebase>
    );
  };

  return Component;
}
