import React from 'react';

const ContentOnlyMobile = props => {
  return (
    <svg
      width="66"
      height="121"
      viewBox="0 0 66 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3196_67566)">
        <rect
          x="4.69318"
          y="4.5"
          width="56.5"
          height="112"
          rx="4.5"
          stroke="white"
          shapeRendering="crispEdges"
        />
      </g>
      <line x1="4.81818" y1="17.5" x2="60.8182" y2="17.5" stroke="white" />
      <circle cx="32.9999" cy="26" r="4.5" fill="#C90451" stroke="white" />
      <g clipPath="url(#clip0_3196_67566)">
        <path
          d="M81.5545 43.0917C119.481 21.7672 146.97 35.496 152.518 39.0677C153.037 39.4016 153.318 39.9835 153.318 40.6005V106.887C153.318 107.992 152.423 108.887 151.318 108.887H-9.68182C-10.7864 108.887 -11.6818 107.992 -11.6818 106.887V79.5512C-11.6818 77.9288 -9.80949 76.9364 -8.32796 77.5975C10.8116 86.1384 43.4898 64.4941 81.5545 43.0917Z"
          fill="#595959"
        />
        <path
          d="M47.2669 66.8803C10.1752 48.4223 -6.12671 45.9546 -10.7261 47.1795C-11.3619 47.3488 -11.6818 47.9781 -11.6818 48.636V106.887C-11.6818 107.992 -10.7864 108.887 -9.68183 108.887H151.318C152.423 108.887 153.318 107.992 153.318 106.887V60.8436C153.318 59.2723 151.597 58.2951 150.23 59.0714C129.955 70.5935 85.5745 85.9432 47.2669 66.8803Z"
          fill="#888888"
        />
      </g>
      <line x1="4.81818" y1="108.5" x2="60.8182" y2="108.5" stroke="white" />
      <defs>
        <filter
          id="filter0_d_3196_67566"
          x="0.193176"
          y="0"
          width="65.5"
          height="121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3196_67566"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3196_67566"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3196_67566">
          <path
            d="M5.31818 26H60.3182V112C60.3182 114.209 58.5273 116 56.3182 116H9.31818C7.10904 116 5.31818 114.209 5.31818 112V26Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContentOnlyMobile;
