import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementColor = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19 14.7265C19 18.7435 15.866 22 12 22C8.13401 22 5 18.7435 5 14.7265C5 10.7095 12 2 12 2C12 2 19 10.7095 19 14.7265Z"
        fill="#8F0339"
        stroke="white"
      />
    </SvgIcon>
  );
};

export default ElementColor;
