import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EyeNormal = props => {
  return (
    <SvgIcon
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5002 16.3792C17.0232 16.3792 20.6899 12.4999 20.6899 12.4999C20.6899 12.4999 17.0232 8.62061 12.5002 8.62061C7.97718 8.62061 4.31055 12.4999 4.31055 12.4999C4.31055 12.4999 7.97718 16.3792 12.5002 16.3792Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5003 15.086C13.9286 15.086 15.0865 13.9281 15.0865 12.4998C15.0865 11.0715 13.9286 9.91357 12.5003 9.91357C11.0719 9.91357 9.91406 11.0715 9.91406 12.4998C9.91406 13.9281 11.0719 15.086 12.5003 15.086Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
      />
    </SvgIcon>
  );
};

export default EyeNormal;
