import React from 'react';

const VMIcon = props => {
  const tempStyle = {
    width: '150px'
  };
  return (
    <img
      style={tempStyle}
      alt="Visiting Media Logo"
      src="https://29v4al1g9wde2l9aye45g80m-wpengine.netdna-ssl.com/wp-content/uploads/2016/12/logo-visiting-media-copy.png"
    />
  );
};

export default VMIcon;
