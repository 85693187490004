import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};

const elementInitialState = {
  elements: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const contentElementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENT_ELEMENTS: {
      const {
        mediaLibId,
        mediaItemId,
        elements,
        status,
        error,
        query
      } = action.payload;
      const { elements: stateElements = [] } = state[`${mediaLibId}${mediaItemId}`] || {};
      const newElements = getNewList(
        query,
        stateElements,
        elements
      );
      const indexes = getListIndexes(newElements);
      return {
        ...state,
        [`${mediaLibId}${mediaItemId}`]: {
          elements: newElements,
          status,
          error,
          indexes
        }
      };
    }
    case types.REFRESH_CONTENT_ELEMENTS: {
      const { mediaLibId, mediaItemId, elementId, element } = action.payload;
      const { elements = [], indexes = {} } = state[`${mediaLibId}${mediaItemId}`] || {};

      if (indexes.hasOwnProperty(elementId)) {
        const newElements = [...elements];

        newElements[indexes[elementId]] = element;

        return {
          ...state,
          [`${mediaLibId}${mediaItemId}`]: {
            ...(state[`${mediaLibId}${mediaItemId}`] || {}),
            elements: newElements
          }
        };
      }

      return state;
    }
    case types.ADD_CONTENT_ELEMENT: {
      const { mediaLibId, mediaItemId, element } = action.payload;
      const { elements = [] } = state[`${mediaLibId}${mediaItemId}`] || {};
      const newElements = [...elements];

      newElements.push(element);

      const indexes = getListIndexes(newElements);

      return {
        ...state,
        [`${mediaLibId}${mediaItemId}`]: {
          ...(state[`${mediaLibId}${mediaItemId}`] || {}),
          elements: newElements,
          indexes
        }
      };
    }
    case types.SET_CONTENT_ELEMENTS_REQUEST_STATUS: {
      const { mediaLibId, mediaItemId, status } = action.payload;
      return {
        ...state,
        [`${mediaLibId}${mediaItemId}`]: {
          ...elementInitialState,
          ...(state[`${mediaLibId}${mediaItemId}`] || {}),
          status
        }
      };
    }
    case types.RESET_CONTENT_ELEMENTS: {
      const { mediaLibId, mediaItemId, elementId } = action.payload;

      if (mediaLibId && mediaItemId && elementId) {
        const { elements: stateElements = [], indexes: stateIndexes = {} } = state[`${mediaLibId}${mediaItemId}`] || {};
        const newElements = [...stateElements];

        if (stateIndexes.hasOwnProperty(elementId)) {
          newElements.splice(stateIndexes[elementId], 1);
        }

        const indexes = getListIndexes(newElements);
        return {
          ...state,
          [`${mediaLibId}${mediaItemId}`]: {
            ...(state[`${mediaLibId}${mediaItemId}`] || {}),
            elements: newElements,
            indexes
          }
        };
      }

      if (mediaLibId && mediaItemId) {
        return { ...state, [`${mediaLibId}${mediaItemId}`]: elementInitialState }
      }

      return state;
    }
    default:
      return state;
  }
};
