import store from '../store';
import VisitingMediaLogoSVG from '../icons/VisitingMediaLogo.svg';
import VisitingMediaNewLogoSVG from '../icons/VisitingMediaNewLogo.svg';

export const getExtension = src => {
  return src
    ? src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif|svg)/i)[0]
    : null;
};

export const getLogoSvg = () => {
  const flags = store.getState().launchdarkly.flags || {};
  const flagKey = 'new-logo-2023';
  const newLogoFlag = flags[flagKey];

  if (flags.hasOwnProperty(flagKey)) {
    return newLogoFlag ? VisitingMediaNewLogoSVG : VisitingMediaLogoSVG;
  }
  return '';
};
