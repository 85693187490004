/* eslint-disable import/no-named-as-default */
// import React from 'react';
// import Loadable from 'react-loadable';
// import LoadingComponent from '../components/LoadingComponent';
import getMenuItems from './menuItems';
import { VMIcon } from '../icons';
import locales from './locales';
import routes from './routes';
import { themes } from './themes';
import grants from './grants';
import firebaseConfig from './firebaseConfig';

// const Loading = () => <LoadingComponent />;

// const LPAsync = Loadable({
//   loader: () => import('../pages/LandingPage'),
//   loading: Loading
// });

// firebase_config_local should be connectd to dev for everything other than firebase/functions

const config = {
  ...firebaseConfig,
  firebase_providers: ['google.com'],
  // drawer_width: 240,
  // appIcon: RMWIcon,
  // configureStore,
  // getMenuItems,
  // locales,
  // themes,
  // grants,
  // routes: [],
  onAuthStateChanged: undefined,
  // notificationsReengagingHours: 48,
  // getNotifications: (notification, props) => {
  //   const { history } = props;
  //   return {
  //     chat: {
  //       path: 'chats',
  //       autoClose: 5000,
  //       // getNotification: () => <div>YOUR CUSTOM NOTIFICATION COMPONENT</div>,
  //       onClick: () => {
  //         history.push('/chats');
  //       },
  //       ...notification
  //     }
  //   };
  // },
  // firebase_providers: [
  //   'google.com',
  //   'facebook.com',
  //   'twitter.com',
  //   'github.com',
  //   'password',
  //   'phone'
  // ],
  // firebase_providers: [
  //   {
  //     provider: 'google',
  //     customParameters: {
  //       hd: 'visitingmedia.com'
  //     }
  //   },
  //   {
  //     provider: 'facebook',
  //     customParameters: {
  //       hd: 'visitingmedia.com'
  //     }
  //   }
  // ],

  // firebase_providers:
  // ['google.com', 'facebook.com', 'twitter.com',
  // 'github.com', 'password', 'phone'],
  initial_state: {
    drawer: {
      open: true,
      mobileOpen: false,
      useMinified: true
    },
    themeSource: {
      isNightModeOn: false,
      source: 'default'
    },
    locale: 'en',
    filters: {
      properties: {
        sortField: 'last-updated',
        sortOrientation: false
      }
    }
  },
  drawer_width: 256,
  appIcon: VMIcon,
  locales,
  themes,
  grants,
  routes,
  getMenuItems,
  firebaseLoad: () => import('./firebase')
};

export default config;
