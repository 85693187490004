import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  contents: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const contentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENTS: {
      const { contents, status, error, query } = action.payload;
      const newContents = getNewList(query, state.contents, contents);
      const indexes = getListIndexes(newContents);
      return {
        ...state,
        contents: newContents,
        status,
        error,
        indexes,
        noResults: !newContents.length
      };
    }
    case types.REFRESH_CONTENTS: {
      const { contentId, content } = action.payload;
      const newContents = [...state.contents];

      newContents[state.indexes[contentId]] = content;

      return { ...state, contents: newContents };
    }
    case types.SET_CONTENTS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CONTENTS:
      return { ...initialState };
    default:
      return state;
  }
};
