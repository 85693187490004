import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DeleteTags = props => {
  return (
    <SvgIcon
      fontSize="small"
      width={58}
      height={51}
      viewBox="0 0 58 51"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="tag_active" stroke="#8F0339">
          <g id="Icon/Tag/Active">
            <g transform="translate(4.000000, 6.000000)">
              <g id="Group-3-Copy-2" transform="translate(6.375000, 2.153846)">
                <polygon
                  id="Path-13"
                  points="23.375 13.7445517 11.0285352 1.23048781 5.47914076 -8.21992047e-17 3.30522057 2.20343042 3.36910932 2.1386744 0 5.55351824 1.21400806 11.1782438 13.5604729 23.6923077 19.0972754 18.080345"
                />
                <circle
                  id="Oval"
                  transform="translate(6.375000, 6.461538) rotate(-45.000000) translate(-6.375000, -6.461538) "
                  cx="6.375"
                  cy="6.46153846"
                  r="2.13947273"
                />
              </g>
            </g>
          </g>
          <g
            id="Group-9"
            transform="translate(15.000000, 7.000000)"
            strokeLinecap="round"
          >
            <line x1="11" y1="23" x2="11" y2="40" id="Stroke-1" />
            <line x1="17" y1="23" x2="15" y2="40" id="Stroke-2" />
            <polygon
              id="Stroke-3"
              points="24 16.6715286 39.7940797 0 42 2.3284714 26.2059203 19"
            />
            <polyline
              id="Stroke-5"
              points="35.2856277 10 37 11.7142857 32.7142511 16 31 14.2857143"
            />
            <polyline
              id="Stroke-6"
              strokeLinejoin="round"
              points="11.3028571 42.9774879 19.5828571 43 23 19 11.3028571 19 11.6971429 19 0 19 3.41714286 43 11.6971429 42.9774879"
            />
            <line x1="5" y1="23" x2="7" y2="40" id="Stroke-8" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DeleteTags;
