import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import firekitReducers from './firekit';
import init from './init';
import dialogs from './dialogs/reducer';
import locale from './locale/reducer';
import persistentValues from './persistentValues/reducer';
import iconsStore from './icons/reducer';
import mediaItemsProgress from './mediaItemsProgress/reducer';
import documentsSelects from './documentsSelects/reducer';
import rootReducer from './rootReducer';
import simpleValues from './simpleValues/reducer';
import experienceLanguages from './experienceLanguages/reducer';
import themeSource from './themeSource/reducer';
import drawer from './drawer/reducer';
import visualBuilderData from '../VisualBuilder/containers/VisualBuilderDataSlice';
import contentLibraryData from '../Dashboard/containers/ContentLibrary/ContentLibrarySlice';
import mainHeaderData from '../VisualBuilder/components/MainHeader/MainHeaderDataSlice';
import globalTabsData from '../VisualBuilder/components/GlobalTabs/GlobalTabsDataSlice';
import experienceElementData from '../VisualBuilder/components/Elements/ElementsDataSlice';
import filters from './filters/reducer';
import launchdarkly from './launchdarkly/reducer';
// v2
import { currentPropertyReducer } from './v2/currentProperty/reducer';
import { currentMediaLibReducer } from './v2/currentMediaLib/reducer';
import { currentMemberReducer } from './v2/currentMember/reducer';
import { currentExperienceReducer } from './v2/currentExperience/reducer';
import { currentUserReducer } from './v2/currentUser/reducer';
import { propertiesReducer } from './v2/properties/reducer';
import { currentExperiencesReducer } from './v2/currentExperiences/reducer';
import { mediaItemsReducer } from './v2/mediaItems/reducer';
import { experiencesReducer } from './v2/experiences/reducer';
import { mediaLibsReducer } from './v2/mediaLibs/reducer';
import { coachesReducer } from './v2/coaches/reducer';
import { usersReducer } from './v2/users/reducer';
import { membersReducer } from './v2/members/reducer';
import { contactsReducer } from './v2/contacts/reducer';
import { currentMediaItemReducer } from './v2/currentMediaItem/reducer';
import { contentsReducer } from './v2/contents/reducer';
import { elementsReducer } from './v2/elements/reducer';
import { contentElementsReducer } from './v2/content-elements/reducer';
import { currentUsersReducer } from './v2/currentUsers/reducer';
import { singleShareExperienceReducer } from './v2/singleShareExperience/reducer';
import { queriesReducer } from './v2/queries/reducer';
import { currentElementReducer } from './v2/currentElement/reducer';
import { iconsReducer } from './v2/icons/reducer';
import { hotspotsReducer } from './v2/hotspots/reducer';
import { univCategoriesReducer } from './v2/univCategories/reducer';
import { univVideosReducer } from './v2/univVideos/reducer';
import { currentShareReducer } from './v2/currentShare/reducer';
import { currentUnivVideoReducer } from './v2/currentUnivVideo/reducer';
import { currentMembersReducer } from './v2/currentMembers/reducer';
import { currentContactReducer } from './v2/currentContact/reducer';
import { contactNotesReducer } from './v2/contactNotes/reducer';
import { contactEventsReducer } from './v2/contactEvents/reducer';
import { messagesReducer } from './v2/messages/reducer';
import { currentPresentationReducer } from './v2/currentPresentation/reducer';
import { currentVisualizationReducer } from './v2/currentVisualization/reducer';
import { visualBuilderValuesReducer } from './v2/visualBuilderValues/reducers';
import { notificationsReducer } from './v2/notifications/reducer';
import { recentExperiencesReducer } from './v2/recentExperiences/reducer';
import { templateExperiencesReducer } from './v2/templateExperiences/reducer';
import { searchedExperiencesReducer } from './v2/searchedExperiences/reducer';
import { checkUrlsReducer } from './v2/checkUrls/reducer';
import { currentMediaItemsReducer } from './v2/currentMediaItems/reducer';

export const appReducers = {
  ...firekitReducers,
  dialogs,
  form: formReducer,
  locale,
  persistentValues,
  simpleValues,
  experienceLanguages,
  iconsStore,
  mediaItemsProgress,
  documentsSelects,
  drawer,
  themeSource,
  visualBuilderData,
  contentLibraryData,
  mainHeaderData,
  globalTabsData,
  experienceElementData,
  filters,
  launchdarkly,
  // v2
  queries: queriesReducer,
  properties: propertiesReducer,
  mediaLibs: mediaLibsReducer,
  coaches: coachesReducer,
  users: usersReducer,
  experiences: experiencesReducer,
  mediaItems: mediaItemsReducer,
  members: membersReducer,
  icons: iconsReducer,
  contacts: contactsReducer,
  contents: contentsReducer,
  elements: elementsReducer,
  hotspots: hotspotsReducer,
  contentElements: contentElementsReducer,
  currentProperty: currentPropertyReducer,
  currentPresentation: currentPresentationReducer,
  currentVisualization: currentVisualizationReducer,
  currentMediaLib: currentMediaLibReducer,
  currentMember: currentMemberReducer,
  currentExperience: currentExperienceReducer,
  singleShareExperience: singleShareExperienceReducer,
  currentExperiences: currentExperiencesReducer,
  currentMediaItem: currentMediaItemReducer,
  currentElement: currentElementReducer,
  currentShare: currentShareReducer,
  currentUser: currentUserReducer,
  currentUsers: currentUsersReducer,
  univCategories: univCategoriesReducer,
  univVideos: univVideosReducer,
  currentUnivVideo: currentUnivVideoReducer,
  currentMembers: currentMembersReducer,
  currentContact: currentContactReducer,
  contactNotes: contactNotesReducer,
  contactEvents: contactEventsReducer,
  messages: messagesReducer,
  visualBuilderValues: visualBuilderValuesReducer,
  notifications: notificationsReducer,
  recentExperiences: recentExperiencesReducer,
  templateExperiences: templateExperiencesReducer,
  searchedExperiences: searchedExperiencesReducer,
  checkUrls: checkUrlsReducer,
  currentMediaItems: currentMediaItemsReducer
};

const appReducer = combineReducers(appReducers);

export default (state, action) => rootReducer(appReducer, init, state, action);
