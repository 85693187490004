import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  members: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBERS: {
      const { members, status, error, query } = action.payload;
      const newMembers = getNewList(query, state.members, members);
      const indexes = getListIndexes(newMembers);
      return {
        ...state,
        members: newMembers,
        status,
        error,
        indexes,
        noResults: !newMembers.length
      };
    }
    case types.REFRESH_MEMBERS: {
      const { memberId, member } = action.payload;
      const newMembers = [...state.members];

      newMembers[state.indexes[memberId]] = member;

      return { ...state, members: newMembers };
    }
    case types.SET_MEMBERS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_MEMBERS:
      return { ...initialState };
    default:
      return state;
  }
};
