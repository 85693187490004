import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  categories: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const univCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_UNIV_CATEGORIES: {
      const { categories, status, error, query } = action.payload;
      const newCategories = getNewList(query, state.categories, categories);
      const indexes = getListIndexes(newCategories);
      return {
        ...state,
        categories: newCategories,
        status,
        error,
        indexes,
        noResults: !newCategories.length
      };
    }
    case types.REFRESH_UNIV_CATEGORIES: {
      const { categoryId, category } = action.payload;
      let newCategories = [...state.categories];

      newCategories[state.indexes[categoryId]] = category;
      newCategories = newCategories.sort((a, b) => a.order - b.order);
      const indexes = getListIndexes(newCategories);

      return { ...state, categories: newCategories, indexes };
    }
    case types.SET_UNIV_CATEGORIES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_UNIV_CATEGORIES:
      return { ...initialState };
    default:
      return state;
  }
};
