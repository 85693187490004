import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Circleplus = props => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        fill="none"
      />
      <path
        d="M10 7.5V13.5"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        fill="none"
      />
      <path
        d="M13 10.5H7"
        stroke={props.htmlColor ? props.htmlColor : '#fff'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default Circleplus;
