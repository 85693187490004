import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RightMenu = props => {
  return (
    <SvgIcon
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 3.5H11" stroke="white" />
      <path d="M14 3.5H19" stroke="white" />
      <path d="M13.5 1V6" stroke="white" strokeLinecap="round" />
      <path d="M19 10.5H8" stroke="white" />
      <path d="M5 10.5H0" stroke="white" />
      <path d="M4.5 13V8" stroke="white" strokeLinecap="round" />
      <path d="M6 17.0002H0" stroke="white" />
      <path d="M19 17.0002H9" stroke="white" />
      <path d="M9 20V15" stroke="white" strokeLinecap="round" />
    </SvgIcon>
  );
};

export default RightMenu;
