import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import * as types from './types';
import * as selectors from './selectors';
import * as initSelectors from '../initialization/selectors';
// import * as loadingSelectors from '../loadings/selectors';
import { logError } from '../errors/actions';
import { logLoading } from '../loadings/actions';

export const valueChanged = (value, location, path, locationValue) => {
  return {
    type: types.VALUE_CHANGED,
    payload: value,
    path,
    location,
    locationValue
  };
};

export const destroy = location => {
  return {
    type: types.DESTROY,
    location
  };
};

export const unWatch = path => {
  return {
    type: types.UNWATCH,
    path
  };
};

export const getRef = (firebaseApp, path) => {
  if (typeof path === 'string' || path instanceof String) {
    const db = getFirestore(firebaseApp);
    return doc(db, path);
  }

  return path;
};

export const getLocation = (firebaseApp, path) => {
  if (typeof path === 'string' || path instanceof String) {
    return path;
  }

  const db = getFirestore(firebaseApp);
  return doc(db, path).path;
};

export function watchDoc(firebaseApp, firebasePath, reduxPath = false) {
  const ref = getRef(firebaseApp, firebasePath);
  const { path } = ref;
  const location = reduxPath || getLocation(firebaseApp, firebasePath);

  return (dispatch, getState) => {
    const isInitialized = initSelectors.isInitialised(getState(), location);

    // const isLoading = loadingSelectors.isLoading(getState(), location);
    // console.log('isLoading', location, isLoading, isInitialized);
    // if (!isInitialized && !isLoading) {
    if (!isInitialized) {
      dispatch(logLoading(location));
      // console.log('location', location, getState());
      const unsub = onSnapshot(
        ref,
        snap => {
          dispatch(valueChanged(snap.data(), location, path, unsub));
        },
        err => {
          console.error(err);
          dispatch(logError(location, err));
        }
      );
    }
  };
}

export function unwatchDoc(firebaseApp, path, reduxPath = false) {
  return (dispatch, getState) => {
    const location = reduxPath || path;
    const allInitializations = selectors.getAllInitializations(getState());
    const unsubs = allInitializations[location];

    if (unsubs) {
      Object.keys(unsubs).map(key => {
        const unsub = unsubs[key];
        if (typeof unsub === 'function') {
          unsub();
        }
        dispatch(unWatch(location));
      });
    }
  };
}

export function destroyDoc(firebaseApp, path, reduxPath = false) {
  const location = reduxPath || path;

  return dispatch => {
    unwatchDoc(firebaseApp, location);
    dispatch(unWatch(location));
    dispatch(destroy(location));
  };
}

export function unwatchAllDocs(firebaseApp) {
  return (dispatch, getState) => {
    const allPaths = selectors.getAllDocs(getState());

    Object.keys(allPaths).forEach(function (key, index) {
      unwatchDoc(firebaseApp, allPaths[index]);
      dispatch(unWatch(key));
    });
  };
}
