import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BellEmpty = ({ read, ...props }) => {
  return (
    <SvgIcon
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.308 4.11h-2.264c-3.311 0-6.15 2.142-6.677 5.114l-1.3 7.365c-0.175 0.987-0.707 1.917-1.525 2.659l-1.303 1.193c-0.786 0.714-1.239 1.703-1.239 2.727v1.039c0 0.52 0.421 0.941 0.941 0.941h24.471c0.52 0 0.941-0.421 0.941-0.941v-1.039c0-1.030-0.458-2.007-1.265-2.722l-1.289-1.18c-0.807-0.732-1.335-1.672-1.513-2.678l-1.3-7.363c-0.508-2.967-3.36-5.114-6.677-5.114zM12.045 5.993h2.264c2.441 0 4.477 1.533 4.823 3.555l1.301 7.369c0.252 1.421 0.989 2.735 2.099 3.741l1.297 1.187 0.169 0.166c0.312 0.343 0.474 0.745 0.474 1.157v0.097h-22.588v-0.097c0-0.485 0.223-0.971 0.625-1.336l1.302-1.192c1.117-1.012 1.861-2.314 2.111-3.722l1.3-7.365c0.36-2.030 2.387-3.559 4.824-3.559z" />
      <path d="M13.176 0c2.176 0 4 1.579 4 3.592 0 0.52-0.421 0.941-0.941 0.941-0.462 0-0.846-0.333-0.926-0.772l-0.015-0.169c0-0.907-0.926-1.709-2.118-1.709-1.201 0-2.118 0.796-2.118 1.709 0 0.52-0.421 0.941-0.941 0.941s-0.941-0.421-0.941-0.941c0-2.016 1.814-3.592 4-3.592z" />
      <path d="M17.765 26.007h-9.176c-0.52 0-0.941 0.421-0.941 0.941 0 2.818 2.493 5.052 5.529 5.052s5.529-2.234 5.529-5.052c0-0.52-0.421-0.941-0.941-0.941zM16.657 27.889l-0.017 0.054c-0.477 1.254-1.831 2.176-3.464 2.176l-0.303-0.011c-1.495-0.106-2.714-0.989-3.161-2.165l-0.019-0.054h6.963z" />
      {read && (
        <path
          fill="#6dd400"
          d="M24.847 5.647c0 3.119-2.528 5.647-5.647 5.647s-5.647-2.528-5.647-5.647c0-3.119 2.528-5.647 5.647-5.647s5.647 2.528 5.647 5.647z"
        />
      )}
    </SvgIcon>
  );
};

export default BellEmpty;
