import * as actionTypes from './types';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import {
  getFilteredResults,
  getFilteredTags,
  getFilteredTypes,
  getQueryInTags,
  getQueryInTypes
} from '../../../utils/visualBuilder';
import { parseParams } from '../../../hooks/useUrlParams';
import { TAB_STATUSES } from '../../../constants/tabStatuses';

const initialState = {};
const visualizationInitialState = {
  visualization: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const currentVisualizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_VISUALIZATION: {
      const {
        visualization,
        propertyId,
        experienceId,
        visualizationId
      } = action.payload;
      const params = parseParams();
      const { tabStatus } = params;
      const {
        types,
        tags,
        typeTags,
        tagTypes,
        primaryFilter,
        typeFilter,
        tagFilter,
        typesOrdered = [],
        tagsOrdered = [],
        contentsOrdered = {},
        mediaItems = [],
        mediaLibTags = [],
        search = {},
        mediaLibHiddenTags = []
      } = visualization;
      
      let {
        leftDrawerAutoHide = false,
        rightDrawerAutoHide = false,
        leftDrawer = true,
        rightDrawer = true
      } = visualization;

      const queryInTypes = getQueryInTypes({ types, typesOrdered });
      const queryInTags = getQueryInTags({
        tags,
        tagsOrdered,
        contentsOrdered
      });

      const filteredTypes = getFilteredTypes({
        queryInTypes,
        primaryFilter,
        tagFilter,
        search
      });
      const filteredTags = getFilteredTags({
        queryInTags,
        primaryFilter,
        typeFilter,
        search,
        mediaLibHiddenTags
      });

      const filteredResults = getFilteredResults({
        primaryFilter,
        typeFilter,
        tagFilter,
        queryInTypes,
        queryInTags,
        types,
        tags,
        typeTags,
        tagTypes,
        mediaLibTags,
        tagsOrdered,
        contentsOrdered,
        search
      });

      if (tabStatus === TAB_STATUSES.PUBLIC) {
        if (mediaItems.length <= 1) {
          leftDrawerAutoHide = true;
          rightDrawerAutoHide = true;
        }
      }

      if (window.innerWidth < 720) {
        leftDrawer = false;
        rightDrawer = false;
      }

      return {
        ...state,
        [`${propertyId}${experienceId}${visualizationId}`]: {
          ...action.payload,
          visualization: {
            ...visualization,
            filteredResults,
            queryInTypes,
            queryInTags,
            filteredTypes,
            filteredTags,
            leftDrawerAutoHide,
            rightDrawerAutoHide,
            leftDrawer,
            rightDrawer
          }
        }
      };
    }
    case actionTypes.REFRESH_CURRENT_VISUALIZATION: {
      const {
        visualization: newVisualization,
        propertyId,
        experienceId,
        visualizationId
      } = action.payload;
      const {
        visualization: stateVisualization
      } = state[`${propertyId}${experienceId}${visualizationId}`];

      const params = parseParams();
      const { tabStatus } = params;

      const visualization = { ...stateVisualization, ...newVisualization };
      const {
        primaryFilter,
        typeFilter,
        tagFilter,
        types,
        tags,
        typeTags,
        tagTypes,
        typesOrdered = [],
        tagsOrdered = [],
        contentsOrdered = {},
        mediaLibTags = [],
        search = {},
        mediaItems = [],
        mediaLibHiddenTags = []
      } = visualization;

      let {
        leftDrawerAutoHide = false,
        rightDrawerAutoHide = false,
        leftDrawer = true,
        rightDrawer = true
      } = visualization;
      
      const queryInTypes = getQueryInTypes({ types, typesOrdered });
      const queryInTags = getQueryInTags({
        tags,
        tagsOrdered,
        contentsOrdered
      });

      const filteredTypes = getFilteredTypes({
        queryInTypes,
        primaryFilter,
        tagFilter,
        search
      });
      const filteredTags = getFilteredTags({
        queryInTags,
        primaryFilter,
        typeFilter,
        search,
        mediaLibHiddenTags
      });

      const filteredResults = getFilteredResults({
        primaryFilter,
        typeFilter,
        tagFilter,
        queryInTypes,
        queryInTags,
        types,
        tags,
        typeTags,
        tagTypes,
        mediaLibTags,
        tagsOrdered,
        contentsOrdered,
        search
      });

      if (tabStatus === TAB_STATUSES.PUBLIC) {
        if (mediaItems.length <= 1) {
          leftDrawerAutoHide = true;
          rightDrawerAutoHide = true;
        }
      }

      return {
        ...state,
        [`${propertyId}${experienceId}${visualizationId}`]: {
          ...state[`${propertyId}${experienceId}${visualizationId}`],
          visualization: {
            ...visualization,
            filteredResults,
            queryInTypes,
            queryInTags,
            filteredTypes,
            filteredTags,
            leftDrawerAutoHide,
            rightDrawerAutoHide,
            leftDrawer,
            rightDrawer
          }
        }
      };
    }
    case actionTypes.SET_CURRENT_VISUALIZATION_REQUEST_STATUS: {
      const { propertyId, experienceId, visualizationId } = action.payload;
      return {
        ...state,
        [`${propertyId}${experienceId}${visualizationId}`]: {
          ...visualizationInitialState,
          ...state[`${propertyId}${experienceId}${visualizationId}`],
          ...action.payload
        }
      };
    }
    case actionTypes.RESET_CURRENT_VISUALIZATION_STATUS: {
      const {
        propertyId,
        experienceId,
        visualizationId
      } = action.payload;
      const keyId = `${propertyId}${experienceId}${visualizationId}`;
      if (propertyId && experienceId && visualizationId && state[keyId]) {
        return {
          ...state,
          [keyId]: {
            ...state[keyId],
            status: REQUEST_STATUSES.IDLE
          }
        };
      }
      return state;
    }
    case actionTypes.RESET_CURRENT_VISUALIZATION: {
      const {
        propertyId = null,
        experienceId = null,
        visualizationId = null
      } = action.payload;
      if (propertyId && experienceId && visualizationId) {
        return {
          ...state,
          [`${propertyId}${experienceId}${visualizationId}`]: visualizationInitialState
        };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
