import * as types from './types';

const initialState = {};

// const getIndex = (prevItems, action) => {
//   return prevItems
//     .map(e => {
//       return e.index;
//     })
//     .indexOf(action.value ? action.value.index : '');
// };

export default function mediaItemsProgress(state = initialState, action) {
  // const prevItems = state[action.id] ? state[action.id] : [];

  switch (action.type) {
    case types.SET_PROGRESS:
      // prevItems[getIndex(prevItems, action)] = action.value;
      return { ...state, [action.id]: action.value.progress };
    default:
      return state;
  }
}
