import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};
const experienceInitialState = {
  experience: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const currentExperiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_EXPERIENCES:
      return { ...state, [action.payload.experienceId]: action.payload };
    case types.REFRESH_CURRENT_EXPERIENCES:
      return { ...state, [action.payload.experienceId]: action.payload };
    case types.SET_CURRENT_EXPERIENCES_REQUEST_STATUS:
      return { ...state, [action.payload.experienceId]: action.payload };
    case types.RESET_CURRENT_EXPERIENCES: {
      const { experienceId = null } = action.payload;
      if (experienceId) {
        return { ...state, [experienceId]: experienceInitialState };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
