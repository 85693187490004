import * as types from './types';

const initialState = {};

export const queriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_QUERY:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          ...action.payload
        }
      };
    case types.RESET_QUERY:
      return { ...initialState };
    default:
      return state;
  }
};
