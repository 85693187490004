/* eslint-disable react/prop-types */

import React from 'react';
import { Accordion, FormControlLabel } from '..';
import { EyeIcon } from '../../../../../../../icons';
import { USER_FIELDS } from '../../../../constants';

function Engagement(props) {
  const { email, values, onChange } = props;

  const iconMarkup = <EyeIcon htmlColor="#000" style={{ fontSize: '16px' }} />;

  return (
    <Accordion
      icon={iconMarkup}
      label="Engagement Notifications"
      disclosure="Engagement notifications notify you whenever your contacts engage with your experiences"
    >
      <FormControlLabel.Phone
        name={USER_FIELDS.ENGAGEMENT_NOTIFICATIONS_SMS_ENABLED}
        values={values}
        onChange={onChange}
      />

      <FormControlLabel.Email
        name={USER_FIELDS.ENGAGEMENT_NOTIFICATIONS_EMAIL_ENABLED}
        values={values}
        onChange={onChange}
        email={email}
      />

      <FormControlLabel.Push
        name={USER_FIELDS.ENGAGEMENT_NOTIFICATIONS_PUSH_ENABLED}
        values={values}
        onChange={onChange}
      />
    </Accordion>
  );
}

export default Engagement;
