import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  experiences: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const experiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPERIENCES: {
      const { experiences, status, error, query } = action.payload;
      const newExperiences = getNewList(query, state.experiences, experiences);
      const indexes = getListIndexes(newExperiences);
      return {
        ...state,
        experiences: newExperiences,
        status,
        error,
        indexes,
        noResults: !newExperiences.length
      };
    }
    case types.REFRESH_EXPERIENCES: {
      const { experienceId, experience } = action.payload;
      const newExperiences = [...state.experiences];

      newExperiences[state.indexes[experienceId]] = experience;

      return { ...state, experiences: newExperiences };
    }
    case types.SET_EXPERIENCES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_EXPERIENCES:
      return { ...initialState };
    default:
      return state;
  }
};
