import { createSlice } from '@reduxjs/toolkit';
// import algoliasearch from 'algoliasearch/lite';
import { isEmpty, isEqual } from 'lodash';
import ContentTypes from '../constants/ContentTypes';

// export const queryAlgolia = createAsyncThunk(
//   'queryAlgolia/fetchByIndexTagAndType',
//   async (
//     {
//       indexId,
//       query = '',
//       filters = '',
//       collectionId = '',
//       algoliaKey = '',
//       algoliaAppId = ''
//     },
//     { rejectWithValue }
//   ) => {
//     const client = algoliasearch(algoliaAppId, algoliaKey);

//     const algoliaIndex = collectionId || indexId;

//     const index = client.initIndex(algoliaIndex);

//     const filtersWithoutOther = filters.includes('_tags:"Other"')
//       ? '_tags:null'
//       : filters;

//     try {
//       const response = index.search({ query, filters: filtersWithoutOther });
//       response.query.hits = response.hits.filter(hit => hit.type !== 'Audio');

//       return { ...response, indexId };
//     } catch (err) {
//       // const error = err; // cast the error for access
//       // if (!error.response) {
//       //   throw err;
//       // }
//       return { query: { hits: [] }, indexId };
//     }
//   }
// );

// const isRejectedAction = action => {
//   return action.type.endsWith('rejected');
// };

const check = (state, index) => {
  if (!state[index]) {
    state[index] = {};
  }
};

const filterByTag = (list, tagFilter) => {
  if (tagFilter && tagFilter !== '') {
    return list.filter(
      item =>
        item._tags.includes(tagFilter) ||
        (tagFilter === 'Other' && (!item._tags || !item._tags.length))
    );
  }
  return list;
};

const filterByType = (list, typeFilter) => {
  if (typeFilter && typeFilter !== '') {
    return list.filter(item => item.type === typeFilter);
  }
  return list;
};

const filteredTags = ({ singleVisibleType, typeResult, queryInTags = [] }) => {
  const typeFilteredList = singleVisibleType
    ? queryInTags
    : queryInTags.filter(tag =>
        tag.hitsWithTag.some(item => item.type === typeResult)
      );

  return typeFilteredList.map(tag => ({
    ...tag,
    hitsWithTag: tag.hitsWithTag.filter(item => item.type === typeResult)
  }));
};

const filteredTypes = ({ tagResult, queryInTypes = [] }) => {
  return (
    queryInTypes.filter(item => {
      return item.hitsWithType.some(
        hit =>
          hit._tags.includes(tagResult) ||
          (tagResult === 'Other' && (!hit._tags || !hit._tags.length))
      );
    }) || []
  );
};

export const VisualBuilderDataSlice = createSlice({
  name: 'visualBuilderData',
  initialState: {},
  reducers: {
    initIndex: (state, { payload }) => {
      const { mediaLibId, primaryFilter } = payload;
      const previousInitialization = state[mediaLibId] || {};
      state[mediaLibId] = {
        rightDrawer: true,
        leftDrawer: true,
        carousel: true,
        leftDrawerAutoHide: true,
        rightDrawerAutoHide: true,
        ...previousInitialization,
        primaryFilter: primaryFilter || 'type',
        elementData: {},
        hotspotMode: { status: false, type: null },
        mapHotspotCanvas: {
          open: false,
          elementId: null,
          url: null
        },
        popoverAction: 'link',
        activeHotspot: null,
        mapPreview: null,
        hotspotReorders: 0,
        fromHotspot: false
      };
    },
    setCarousel: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].carousel = value;
    },
    toggleGridView: (state, { payload }) => {
      const { indexId } = payload;
      check(state, indexId);
      state[indexId].gridView = !state[indexId].gridView;
    },
    setGridView: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].gridView = value;
    },
    openBoth: (state, { payload }) => {
      const { indexId } = payload;
      check(state, indexId);
      state[indexId].rightDrawer = true;
      state[indexId].leftDrawer = true;
    },
    closeBoth: (state, { payload }) => {
      const { indexId } = payload;
      check(state, indexId);
      state[indexId].rightDrawer = false;
      state[indexId].leftDrawer = false;
    },
    setLeft: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].leftDrawer = value;
    },
    setRight: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].rightDrawer = value;
    },
    setPrimaryFilter: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, payload);
      state[indexId].primaryFilter = value;
      state[indexId].tagFilter = '';
      state[indexId].typeFilter = '';
    },
    setTypeTagFilter: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].typeTagFilter = value;
    },
    setSearchField: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].searchField = value;
    },
    setSearch: (state, { payload }) => {
      const {
        indexId,
        term = undefined,
        tags = [],
        tagsCustomize = [],
        searchAndOr = true
      } = payload;
      check(state, indexId);

      if (
        (term || tags.length) &&
        !state[indexId].typeFilter &&
        !state[indexId].tagFilter
      ) {
        state[indexId].tagFilter = '';
        state[indexId].typeFilter = '';
      }
      state[indexId].search = {
        term,
        tags,
        tagsCustomize,
        searchAndOr
      };
    },
    setPrevFilters: (state, { payload }) => {
      const { indexId } = payload;

      check(state, indexId);

      state[indexId].prevTagFilter = state[indexId].tagFilter;
      state[indexId].prevTypeFilter = state[indexId].typeFilter;
      state[indexId].prevPrimaryFilter = state[indexId].primaryFilter;
      state[indexId].prevCurrentSlide = state[indexId].currentSlide;
      state[indexId].prevSearch = state[indexId].search;
    },
    resetPrevFilters: (state, { payload }) => {
      const { indexId } = payload;

      check(state, indexId);

      state[indexId].prevTagFilter = '';
      state[indexId].prevTypeFilter = '';
      state[indexId].prevPrimaryFilter = '';
      state[indexId].prevCurrentSlide = null;
      state[indexId].prevSearch = {};
    },
    setResetSearch: (state, { payload }) => {
      const {
        indexId,
        resetButton,
        resetPrevSearch,
        resetPrevFilters,
        resetCurrentSlide = false
      } = payload;
      check(state, indexId);

      if (resetButton !== undefined) {
        state[indexId].resetButton = resetButton;
      }

      if (resetPrevSearch) {
        state[indexId].prevSearch = {};
      }

      if (resetPrevFilters) {
        state[indexId].primaryFilter = 'type';
        state[indexId].prevTagFilter = '';
        state[indexId].prevTypeFilter = '';
      }

      if (resetButton) {
        state[indexId].tagFilter = '';
        state[indexId].typeFilter = '';
        state[indexId].search = {};
      }

      if (resetCurrentSlide) {
        state[indexId].currentSlide = null;
      }
    },
    // setIsHomebase: (state, { payload }) => {
    //   const { indexId, isHomebase } = payload;
    //   check(state, indexId);
    //   state[indexId].isHomebase = isHomebase;
    // },
    setNewExperience: (state, { payload }) => {
      const { indexId, id } = payload;
      check(state, indexId);
      state[indexId].newExperience = id;
    },
    setSingleShareExperience: (state, { payload }) => {
      const { indexId, id } = payload;
      check(state, indexId);
      state[indexId].singleShareExperience = id;
    },
    setCurrentExperience: (state, { payload }) => {
      const { indexId, id } = payload;
      check(state, indexId);
      state[indexId].currentExperience = id;
    },
    setCurrentSlide: (state, { payload }) => {
      const { indexId, item = {} } = payload;

      const {
        name,
        objectID,
        type,
        description,
        thumbnails,
        link,
        XFrameOptions,
        _tags,
        slideID
      } = item;

      check(state, indexId);
      state[indexId].currentSlide = {
        ...(name && { name }),
        ...(objectID && { objectID }),
        ...(type && { type }),
        ...(description && { description }),
        ...(thumbnails && { thumbnails }),
        ...(link && { link }),
        ...(XFrameOptions && { XFrameOptions }),
        ...(_tags && { _tags }),
        ...(slideID && { slideID })
      };
    },

    setFilter: (state, { payload }) => {
      const {
        indexId,
        tagFilterValue,
        typeFilterValue,
        tagSelectedByClick,
        tag,
        type
      } = payload;
      const {
        tagFilter = '',
        typeFilter = '',
        primaryFilter = '',
        merged = [],
        tabStatus,
        contentsOrdered
      } = state[indexId];

      if (tabStatus === 'share') {
        return;
      }

      let { queryInTags = [], queryInTypes = [], search = {} } = state[indexId];

      check(state, indexId);

      state[indexId].tagSelectedByClick = tagSelectedByClick;

      let filteredResults = [];
      const visibleTypes = queryInTypes.filter(item => !item.hide);
      const singleVisibleType = visibleTypes.length <= 1;
      // Filter out hidden tags or types if in preview or public
      if (tabStatus === 'public' || tabStatus === 'share') {
        if (!singleVisibleType) {
          // queryInTypes = queryInTypes.filter(type2 => !type2.hide);
        }
        queryInTags = queryInTags.filter(tag2 => !tag2.hide);
        if (tabStatus === 'homebase') {
          // treat as none only 1 exists
          if (queryInTypes.length <= 1) {
            queryInTypes = [];
          }
          // treat as none only 1 exists
          if (queryInTags.length <= 1) {
            queryInTags = [];
          }
        }
      }
      const queryTypeValue =
        (type && queryInTypes && queryInTypes[0]?.value) || undefined;
      const queryTagValue =
        (tag && queryInTags && queryInTags[0]?.value) || undefined;
      const visibleTypeFilter = singleVisibleType ? '' : undefined;
      const typeResult =
        typeFilterValue !== undefined
          ? typeFilterValue
          : visibleTypeFilter || queryTypeValue || typeFilter;
      const tagResult =
        tagFilterValue !== undefined
          ? tagFilterValue
          : (tag && queryInTags[0]?.value) || tagFilter;
      let tagFilterOverride = '';
      let typeFilterOverride = '';
      // Create filtered Tags
      if (typeResult && primaryFilter === 'type') {
        const filteredTagsResult = filteredTags({
          singleVisibleType,
          typeResult,
          queryInTags
        });
        state[indexId].filteredTags = filteredTagsResult;
      } else if (!isEqual(queryInTags, state[indexId].filteredTags)) {
        state[indexId].filteredTags = queryInTags;
      }

      if (tabStatus === 'public') {
        let mediaItems = [];
        state[indexId].filteredTags.forEach(filteredTag => {
          mediaItems = mediaItems.concat(filteredTag.hitsWithTag || []);
        });

        if (mediaItems.length) {
          mediaItems = mediaItems.map(item => item.objectID);

          if (window.innerWidth < 720) {
            state[indexId].rightDrawer = false;
          }

          state[indexId].rightDrawerAutoHide =
            [...new Set(mediaItems)].length <= 1;
          state[indexId].carousel = !([...new Set(mediaItems)].length <= 1);
        }
      }

      if (tagResult && primaryFilter === 'tag') {
        const filteredTypesResult = filteredTypes({
          tagResult,
          queryInTypes
        });
        state[indexId].filteredTypes = filteredTypesResult;
      } else if (!isEqual(queryInTypes, state[indexId].filteredTypes)) {
        state[indexId].filteredTypes = queryInTypes;
      }
      if (
        primaryFilter === 'tag' &&
        state[indexId].tabStatus === 'collect' &&
        state[indexId].prevTabStatus === 'customize'
      ) {
        typeFilterOverride = state[indexId].prevTypeFilter;
        state[indexId].typeFilter = state[indexId].prevTypeFilter;
      }
      if (primaryFilter === 'type') {
        let searchedTagValue = '';
        if (search.tags?.length) {
          searchedTagValue = state[indexId].filteredTags.find(
            filteredTag => search.tags.indexOf(filteredTag.value) > -1
          )?.value;
        }
        tagFilterOverride =
          tagFilterValue !== undefined
            ? tagFilterValue
            : searchedTagValue || state[indexId].filteredTags[0]?.value;
      }
      state[indexId].tagFilter =
        tagFilterOverride === undefined ? '' : tagFilterOverride || tagResult;
      state[indexId].typeFilter =
        typeFilterOverride === undefined
          ? ''
          : typeFilterOverride || typeResult;
      if (singleVisibleType && tabStatus !== 'homebase') {
        state[indexId].typeFilter = '';
      }

      if (primaryFilter === 'type') {
        const filteredByType = filterByType(merged, state[indexId].typeFilter);

        filteredResults = singleVisibleType
          ? filteredByType
          : filterByTag(filteredByType, state[indexId].tagFilter);
      } else if (primaryFilter === 'tag') {
        const filteredByTag = filterByTag(merged, state[indexId].tagFilter);
        filteredResults = singleVisibleType
          ? filteredByTag
          : filterByType(filteredByTag, state[indexId].typeFilter);
      }
      const queryInTagsCurrent = queryInTags.map(item =>
        item.hitsWithTag.map(hit => hit.objectID)
      );
      const queryInTypesCurrent = queryInTypes.map(item =>
        item.hitsWithType.map(hit => hit.objectID)
      );
      const queryCurrent =
        primaryFilter === 'tag' || tag
          ? queryInTagsCurrent
          : queryInTypesCurrent;

      const mediaItemsOrder = [...new Set([...queryCurrent].flat(1))].filter(
        item => filteredResults.some(result => result.objectID === item)
      );

      filteredResults = mediaItemsOrder.map(item =>
        filteredResults.find(item2 => item2.objectID === item)
      );

      if (
        contentsOrdered &&
        (tabStatus === 'customize' || tabStatus === 'public')
      ) {
        filteredResults = filteredResults.sort((a, b) => {
          const aOrder = contentsOrdered[a.objectID];
          const bOrder = contentsOrdered[b.objectID];
          return aOrder - bOrder;
        });
      } else {
        filteredResults = filteredResults.sort((a, b) => {
          const aName = `${a?._tags[0] || 'other'}${a?.name || ''}`
            .toLowerCase()
            .trim();
          const bName = `${b?._tags[0] || 'other'}${b?.name || ''}`
            .toLowerCase()
            .trim();
          return aName?.localeCompare(bName, 'en', { numeric: true });
        });
      }

      state[indexId].filteredResults = filteredResults;

      let filteredResultsWithDuplicates = [...filteredResults];

      if (primaryFilter === 'type') {
        if (!state[indexId].tagFilter) {
          const allTagArrayArray = [...state[indexId].filteredTags].map(tag =>
            tag?.hitsWithTag.map(item => {
              return { objectID: item?.objectID, slideID: item?.slideID };
            })
          );
          const allTagArray = [].concat(...allTagArrayArray);
          filteredResultsWithDuplicates = allTagArray.map(
            ({ objectID, slideID }, index) => {
              const item = {
                ...(filteredResults.find(item =>
                  objectID.includes(item?.objectID)
                ) || {}),
                ...{ slideID }
              };
              item.objectID = objectID;
              return item;
            }
          );
        }
      }

      state[indexId].filteredResultsWithDuplicates =
        filteredResultsWithDuplicates;
    },
    toggleShowChildren: (state, { payload }) => {
      const { indexId, label } = payload;
      check(state, indexId);
      if (!state[indexId].tagsToggled) {
        state[indexId].tagsToggled = {};
      }
      state[indexId].tagsToggled[label] = !state[indexId].tagsToggled[label];
    },
    setToggleShowChildren: (state, { payload }) => {
      const { indexId, value } = payload;
      check(state, indexId);
      state[indexId].tagsToggled = value;
    },
    setTabStatus: (state, { payload }) => {
      const { indexId, tabStatus } = payload;
      check(state, indexId);
      if (state[indexId].tabStatus !== tabStatus) {
        state[indexId].prevTabStatus = state[indexId].tabStatus;
      }
      state[indexId].tabStatus = tabStatus;
    },
    setHotspotMode: (state, { payload }) => {
      const { indexId, hotspotMode } = payload;
      check(state, indexId);
      state[indexId].hotspotMode = hotspotMode;
    },
    setPopoverAction: (state, { payload }) => {
      const { indexId, popoverAction } = payload;
      check(state, indexId);
      state[indexId].popoverAction = popoverAction;
    },
    setHoveredHotspot: (state, { payload }) => {
      const { indexId, hoveredHotspot } = payload;
      check(state, indexId);
      state[indexId].hoveredHotspot = hoveredHotspot;
    },
    setActiveHotspot: (state, { payload }) => {
      const { indexId, activeHotspot } = payload;
      check(state, indexId);
      state[indexId].activeHotspot = activeHotspot;
    },
    setHotspotReorders: (state, { payload }) => {
      const { indexId, hotspotReorders } = payload;
      check(state, indexId);
      state[indexId].hotspotReorders += hotspotReorders;
    },
    setFromHotspot: (state, { payload }) => {
      const { indexId, fromHotspot } = payload;
      check(state, indexId);
      state[indexId].fromHotspot = fromHotspot;
    },
    setMapPreview: (state, { payload }) => {
      const { indexId, mapPreview } = payload;
      check(state, indexId);
      state[indexId].mapPreview = mapPreview;
    },
    setMapHotspotCanvas: (state, { payload }) => {
      const { indexId, mapHotspotCanvas } = payload;
      check(state, indexId);
      state[indexId].mapHotspotCanvas = mapHotspotCanvas;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  // setIsHomebase,
  setSearch,
  setResetSearch,
  setSearchField,
  setCurrentSlide,
  toggleShowChildren,
  setChildrenVisibility,
  setFilter,
  setPrevFilters,
  resetPrevFilters,
  setPrimaryFilter,
  initIndex,
  setTabStatus,
  setHotspotMode,
  setMapHotspotCanvas,
  setPopoverAction,
  setHoveredHotspot,
  setActiveHotspot,
  setHotspotReorders,
  setFromHotspot,
  setMapPreview,
  openBoth,
  closeBoth,
  setCarousel,
  toggleGridView,
  setLeft,
  setRight,
  setTypeTagFilter,
  setGridView,
  setToggleShowChildren,
  setElementData,
  setNewExperience,
  setCurrentExperience,
  setSingleShareExperience
} = VisualBuilderDataSlice.actions;

export default VisualBuilderDataSlice.reducer;
