import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconArrowSide = props => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6157 1.13086L19.0579 1.36425L19.445 0.630859H18.6157V1.13086ZM13.0991 11.5829L12.6661 11.8329L13.1188 12.6169L13.5413 11.8163L13.0991 11.5829ZM7.06465 1.13086V0.630859H6.19862L6.63163 1.38086L7.06465 1.13086ZM8.71139 3.9831L8.99234 4.3967L9.37677 4.13557L9.1444 3.7331L8.71139 3.9831ZM0.956096 19.0636L0.456097 19.0646L0.457033 19.5646L0.957032 19.5636L0.956096 19.0636ZM5.78367 19.0546L5.78461 19.5546L6.28461 19.5537L6.28367 19.0537L5.78367 19.0546ZM11.1334 8.17819L11.5664 7.92819L11.2915 7.452L10.8435 7.77081L11.1334 8.17819ZM18.1735 0.897472L12.6569 11.3495L13.5413 11.8163L19.0579 1.36425L18.1735 0.897472ZM7.06465 1.63086H18.6157V0.630859H7.06465V1.63086ZM9.1444 3.7331L7.49766 0.880859L6.63163 1.38086L8.27838 4.2331L9.1444 3.7331ZM8.43044 3.5695C3.14589 7.15919 0.443675 12.4264 0.456097 19.0646L1.4561 19.0627C1.44426 12.7395 3.99612 7.79054 8.99234 4.3967L8.43044 3.5695ZM0.957032 19.5636L5.78461 19.5546L5.78274 18.5546L0.955161 18.5636L0.957032 19.5636ZM6.28367 19.0537C6.27505 14.4483 7.95253 11.0555 11.4233 8.58556L10.8435 7.77081C7.0867 10.4443 5.2745 14.1537 5.28367 19.0555L6.28367 19.0537ZM13.5321 11.3329L11.5664 7.92819L10.7004 8.42819L12.6661 11.8329L13.5321 11.3329Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default IconArrowSide;
