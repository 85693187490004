import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckMark = props => {
  return (
    <SvgIcon
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 9.02619L5.64516 15L13 1"
        stroke={props.htmlColor ? props.htmlColor : '#6DD400'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default CheckMark;
