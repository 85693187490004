import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  property: {},
  status: REQUEST_STATUSES.IDLE,
  error: null
};

export const currentPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_PROPERTY:
      return { ...state, ...action.payload };
    case types.REFRESH_CURRENT_PROPERTY: {
      const { property } = action.payload;
      if (!property.mediaLibId) {
          property.mediaLibId = property['media-libs']?.[0];
      }
      
      return { ...state, property };
    }
    case types.SET_CURRENT_PROPERTY_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_PROPERTY:
      return { ...initialState };
    default:
      return state;
  }
};
