import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const DownloadFileIcon = props => {
  const { color = '#333333' } = props;
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M10 13L10 1" stroke={color} strokeLinecap="round" />
      <path
        d="M14 9L10 14L6 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1 13V19H19V13"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default DownloadFileIcon;
