import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
  coaches: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false
};

export const coachesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COACHES: {
      const { coaches, status, error, query } = action.payload;
      const newCoaches = getNewList(query, state.coaches, coaches);
      const indexes = getListIndexes(newCoaches);
      return {
        ...state,
        coaches: newCoaches,
        status,
        error,
        indexes,
        noResults: !newCoaches.length
      };
    }
    case types.REFRESH_COACHES: {
      const { userId, coach } = action.payload;
      const newCoaches = [...state.coaches];

      newCoaches[state.indexes[userId]] = coach;

      return { ...state, coaches: newCoaches };
    }
    case types.SET_COACHES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_COACHES:
      return { ...initialState };
    default:
      return state;
  }
};
