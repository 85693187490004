export const currentEnv = () => {
  if (process.env.REACT_APP_LOCAL === 'true') {
    return 'local';
  }
  if (process.env.REACT_APP_DEMO === 'true') {
    return 'demo';
  }
  if (process.env.REACT_APP_QA === 'true') {
    return 'qa';
  }
  if (process.env.REACT_APP_STAGE === 'true') {
    return 'staging';
  }
  if (process.env.REACT_APP_DEV === 'true') {
    return 'dev';
  }
  if (process.env.REACT_APP_DEV_2 === 'true') {
    return 'dev2';
  }
  if (process.env.REACT_APP_PROD === 'true') {
    return 'production';
  }
  return 'unknown';
};

// Why Did You Render
export const wdyr = () => {
  // if (process.env.REACT_APP_LOCAL === 'true') {
  //   return true;
  // }
  return false;
};

// Bugherd
export const bugherd = () => {
  return (
    (process.env.REACT_APP_DEV === 'true' &&
      process.env.REACT_APP_LOCAL !== 'true') ||
    process.env.REACT_APP_STAGE === 'true'
  );
};

// Redux Logger
export const reduxDevTools = () => {
  return process.env.REACT_APP_DEV === 'true';
};

// Redux Logger
export const reduxLogger = () => {
  return false;
};

// Redux Persist
export const reduxPersist = () => {
  return (
    (process.env.REACT_APP_DEV === 'true' &&
      process.env.REACT_APP_LOCAL !== 'true') ||
    process.env.REACT_APP_STAGE === 'true' ||
    process.env.REACT_APP_DEMO === 'true' ||
    process.env.REACT_APP_QA === 'true' ||
    process.env.REACT_APP_PROD === 'true'
  );
};

// register ServiceWorker
export const serviceWorkerSetting = () => {
  // if (
  //   (process.env.REACT_APP_STAGE === 'true' ||
  //     process.env.REACT_APP_PROD === 'true') &&
  //   'serviceWorker' in navigator
  // ) {
  //   return true;
  // }
  return true;
};

// Firebase Performance
export const firebasePerformance = () => {
  // if (
  //   (process.env.REACT_APP_DEV === 'true' &&
  //     process.env.REACT_APP_LOCAL !== 'true') ||
  //   process.env.REACT_APP_STAGE === 'true' ||
  //   process.env.REACT_APP_PROD === 'true'
  // ) {
  //   return true;
  // }
  return false;
};

// Firebase Performance
export const firebaseAnalytics = () => {
  // if (
  //   process.env.REACT_APP_PROD === 'true' ||
  //   process.env.REACT_APP_STAGE === 'true'
  // ) {
  //   return true;
  // }
  return false;

  // return true;
};
