import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementSettings = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        fill="none"
        clipRule="evenodd"
        d="M15.9555 9.38733C15.9889 9.10031 16.0079 8.80878 16.0079 8.51275C16.0079 8.25823 15.9949 8.00675 15.9702 7.75873L14.145 7.10963C14.0113 6.59514 13.8072 6.10926 13.5453 5.66123L14.396 3.87172C14.0671 3.45555 13.6967 3.07388 13.2882 2.73591L11.4839 3.59351C11.034 3.32892 10.5456 3.12283 10.0282 2.9875L9.34445 1.06454C9.07127 1.03446 8.794 1.01758 8.51275 1.01758C8.27259 1.01758 8.03537 1.03005 7.80087 1.05217L7.11383 2.98414C6.59399 3.11895 6.10329 3.32546 5.65127 3.59089L3.78031 2.70153C3.38521 3.02366 3.02366 3.38521 2.70153 3.78031L3.59089 5.65127C3.32546 6.10329 3.11895 6.59399 2.98414 7.11383L1.05217 7.80087C1.03005 8.03537 1.01758 8.27259 1.01758 8.51275C1.01758 8.794 1.03446 9.07117 1.06465 9.34445L2.9875 10.0281C3.12283 10.5456 3.32892 11.034 3.59361 11.4839L2.73591 13.2882C3.07388 13.6966 3.45555 14.0671 3.87182 14.3959L5.66123 13.5453C6.10937 13.8071 6.59524 14.0112 7.10974 14.145L7.75872 15.9702C8.00675 15.9949 8.25823 16.0079 8.51275 16.0079C8.80889 16.0079 9.10031 15.9889 9.38733 15.9555L10.0324 14.1417C10.5443 14.0074 11.028 13.8037 11.4739 13.5426L13.1973 14.3617C13.6268 14.0174 14.0174 13.6268 14.3618 13.1973L13.5426 11.4738C13.8038 11.0279 14.0075 10.5443 14.1418 10.0323L15.9555 9.38733Z"
        stroke={htmlColor || '#fff'}
      />
      <path
        fillRule="evenodd"
        fill="none"
        clipRule="evenodd"
        d="M12.0775 8.51336C12.0775 10.4817 10.4818 12.0775 8.51336 12.0775C6.5449 12.0775 4.94922 10.4817 4.94922 8.51336C4.94922 6.5449 6.5449 4.94922 8.51336 4.94922C10.4818 4.94922 12.0775 6.5449 12.0775 8.51336Z"
        stroke={htmlColor || '#fff'}
      />
    </SvgIcon>
  );
};

export default ElementSettings;
