import {
  getMessaging,
  onMessage as fbOnMessage,
  getToken
} from 'firebase/messaging';
import * as types from './types';

export function onTokenChanged(token) {
  return {
    type: types.TOKEN_CHANGED,
    payload: { token, isInitialized: true }
  };
}

export function onPermissionChanged(hasPermission, onMessage) {
  return {
    type: types.PERMISSION_CHANGED,
    payload: { hasPermission, isInitialized: true }
  };
}

export function onMessage(message) {
  return {
    type: types.ON_MESSAGE,
    payload: { message }
  };
}
export function clearMessage() {
  return {
    type: types.ON_CLEAR_MESSAGE
  };
}

export function onMessagingError(error) {
  return {
    type: types.MESSAGING_ERROR,
    payload: { error }
  };
}

export function initMessaging(
  firebaseApp,
  handleTokenChange,
  onMessageReceieved,
  onBackgroundMessageReceived
) {
  return async dispatch => {
    const messaging = getMessaging(firebaseApp);

    try {
      const token = await getToken(messaging);
      if (
        handleTokenChange !== undefined &&
        handleTokenChange instanceof Function
      ) {
        handleTokenChange(token);
      }

      dispatch(onPermissionChanged(true));
      dispatch(onTokenChanged(token));
    } catch (e) {
      dispatch(onPermissionChanged(false));
      console.warn(e);
    }

    fbOnMessage(messaging, payload => {
      if (
        onMessageReceieved !== undefined &&
        onMessageReceieved instanceof Function
      ) {
        onMessageReceieved(payload);
      }

      dispatch(onMessage(payload));
    });
  };
}
