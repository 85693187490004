/* eslint-disable react/jsx-key */
import React from 'react';
import { Route } from 'react-router-dom';
import RestrictedRoute from '../containers/RestrictedRoute';
import makeLoadable from '../containers/MyLoadable';
import { ROLES } from '../constants/roles';

const MyLoadable = (opts, preloadComponents) =>
  makeLoadable(
    { ...opts, firebase: () => import('./firebase') },
    preloadComponents
  );

const AsyncSignIn = MyLoadable({
  loader: () => import('../Dashboard/pages/SignIn')
});
const AsyncMyAccount = MyLoadable({
  loader: () => import('../Dashboard/pages/MyAccount')
});
const AsyncUserProfile = MyLoadable({
  loader: () => import('../Dashboard/pages/UserProfile')
});
const AsyncComingSoon = MyLoadable({
  loader: () => import('../Dashboard/pages/ComingSoon')
});
const AsyncDashboard = MyLoadable({
  loader: () => import('../Dashboard/pages/Dashboard')
});
const AsyncPropertyMembers = MyLoadable({
  loader: () => import('../Dashboard/pages/Properties/Members/Members')
});
const AsyncPropertyCoaches = MyLoadable({
  loader: () => import('../Dashboard/pages/Properties/Coaches/Coaches')
});
const AsyncAllUsers = MyLoadable({
  loader: () => import('../Dashboard/pages/Properties/Users/Users')
});
const AsyncPropertySettings = MyLoadable({
  loader: () =>
    import('../Dashboard/pages/Properties/Settings/PropertySettings')
});
const AsyncProperties = MyLoadable({
  loader: () => import('../Dashboard/pages/Properties/Properties')
});
const AsyncIntegrations = MyLoadable({
  loader: () => import('../Dashboard/pages/Integrations/Integrations')
});
const AsyncUniversity = MyLoadable({
  loader: () => import('../Dashboard/pages/University/University')
});
const AsyncContacts = MyLoadable({
  loader: () => import('../Dashboard/pages/Contacts/Contacts')
});
const AsyncExperiences = MyLoadable({
  loader: () => import('../Dashboard/pages/Experiences/Experiences')
});
const AsyncContentLibrary = MyLoadable({
  loader: () => import('../Dashboard/pages/ContentLibrary/ContentLibrary')
});
const AsyncIconLibrary = MyLoadable({
  loader: () => import('../Dashboard/pages/IconLibrary/IconLibrary')
});
const AsyncContentLibraries = MyLoadable({
  loader: () => import('../Dashboard/pages/ContentLibraries/ContentLibraries')
});
const AsyncContactDetails = MyLoadable({
  loader: () => import('../Dashboard/pages/Contacts/ContactDetails')
});
const AsyncResetPassword = MyLoadable({
  loader: () => import('../Dashboard/components/Forms/AuthForms/ResetPassword')
});
const AsyncForgotPasswordForm = MyLoadable({
  loader: () =>
    import('../Dashboard/components/Forms/AuthForms/SendPasswordResetForm')
});
const AsyncDoesNotExist = MyLoadable({
  loader: () => import('../Dashboard/pages/DoesNotExist/DoesNotExist')
});
const AsyncPropertySelector = MyLoadable({
  loader: () => import('../Dashboard/pages/PropertySelector/PropertySelector')
});
const AsyncExperience = MyLoadable({
  loader: () => import('../VisualBuilder')
});

const getAppRoutes = () => {
  return [
    <Route path="/resetPassword" exact component={AsyncResetPassword} />,
    <Route path="/create-account" exact component={AsyncSignIn} />,
    <Route path="/create-account/:token" exact component={AsyncSignIn} />,
    <Route path="/create-account/:token/:set" exact component={AsyncSignIn} />,
    <RestrictedRoute type="public" path="/signin" component={AsyncSignIn} />,
    <RestrictedRoute
      type="private"
      path="/profile"
      exact
      component={AsyncMyAccount}
    />,
    <RestrictedRoute
      type="private"
      path="/profile/:userId"
      exact
      component={AsyncUserProfile}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties"
      exact
      component={AsyncProperties}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/integrations/"
      exact
      component={AsyncIntegrations}
      featureFlag="site-integrations"
    />,
    <RestrictedRoute
      type="private"
      path="/university"
      exact
      component={AsyncUniversity}
      featureFlag="university"
    />,
    <RestrictedRoute
      type="private"
      path="/university/:videoId"
      exact
      component={AsyncUniversity}
      featureFlag="university"
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/university"
      exact
      component={AsyncUniversity}
      featureFlag="university"
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/members/"
      exact
      component={AsyncPropertyMembers}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.COACH_ADMIN}
      type="private"
      path="/coaches"
      exact
      component={AsyncPropertyCoaches}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.COACH_ADMIN}
      type="private"
      path="/users"
      exact
      component={AsyncAllUsers}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/settings"
      exact
      component={AsyncPropertySettings}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/settings/icons"
      exact
      component={AsyncIconLibrary}
    />,
    <RestrictedRoute
      type="private"
      path="/"
      exact
      component={AsyncDashboard}
    />,
    <Route
      path="/sendPasswordResetForm"
      exact
      component={AsyncForgotPasswordForm}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/media"
      exact
      component={AsyncContentLibraries}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/media/"
      exact
      component={AsyncContentLibrary}
    />,
    <RestrictedRoute
      type="private"
      path="/propertyselector"
      exact
      component={AsyncPropertySelector}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/start"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/experiences"
      exact
      component={AsyncExperiences}
    />,
    <RestrictedRoute
      type="public"
      path="/properties/:uid/experiences/:uid2"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/experiences/:uid2/collect"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/experiences/:uid2/customize"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/experiences/:uid2/preview"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="public"
      path="/properties/:uid/experiences/:uid2/:uid3"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/experiences/:uid2/:uid3/:uid4"
      exact
      component={AsyncExperience}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/collections"
      exact
      component={AsyncComingSoon}
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/billing"
      exact
      component={AsyncComingSoon}
      featureFlag="billing-tab"
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/analytics"
      exact
      component={AsyncComingSoon}
      featureFlag="analytics-tab"
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/analytics"
      exact
      component={AsyncComingSoon}
      featureFlag="analytics-tab"
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/hub"
      exact
      component={AsyncComingSoon}
      featureFlag="content-hub"
    />,
    <RestrictedRoute
      restrictedTo={ROLES.PROPERTY_ADMIN}
      type="private"
      path="/properties/:uid/hub"
      exact
      component={AsyncComingSoon}
      featureFlag="content-hub"
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/contacts"
      exact
      component={AsyncContacts}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/contacts/:contactId"
      exact
      component={AsyncContactDetails}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/inbox"
      exact
      component={AsyncComingSoon}
    />,
    <RestrictedRoute
      type="private"
      path="/properties/:uid/hub"
      exact
      component={AsyncComingSoon}
      featureFlag="content-hub"
    />,
    <Route component={AsyncDoesNotExist} />
  ];
};

export default getAppRoutes;
