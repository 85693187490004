import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LinkU = props => {
  return (
    <SvgIcon
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.85714C1 2.7269 3.01472 1 5.5 1H11.5C13.9853 1 16 2.7269 16 4.85714C16 6.98738 13.9853 8.71429 11.5 8.71429H5.5C3.01472 8.71429 1 6.98738 1 4.85714Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        fill="none"
      />
      <path
        d="M6 9.14286C6 7.01262 8.01472 5.28571 10.5 5.28571H16.5C18.9853 5.28571 21 7.01262 21 9.14286C21 11.2731 18.9853 13 16.5 13H10.5C8.01472 13 6 11.2731 6 9.14286Z"
        stroke={props.htmlColor ? props.htmlColor : '#333333'}
        fill="none"
      />
    </SvgIcon>
  );
};

export default LinkU;
