import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FolderInTrash = props => {
  return (
    <SvgIcon width={55} height={56} viewBox="0 0 55 56" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Folder-In-Trash" transform="translate(-20.000000, -21.000000)">
          <g id="Group-6">
            <g
              id="Group-9"
              transform="translate(32.000000, 33.000000)"
              stroke="#8F0339"
              strokeLinecap="round"
            >
              <line x1="11" y1="23" x2="11" y2="40" id="Stroke-1" />
              <line x1="17" y1="23" x2="15" y2="40" id="Stroke-2" />
              <polygon
                id="Stroke-3"
                points="24 16.6715286 39.7940797 0 42 2.3284714 26.2059203 19"
              />
              <polyline
                id="Stroke-5"
                points="35.2856277 10 37 11.7142857 32.7142511 16 31 14.2857143"
              />
              <polyline
                id="Stroke-6"
                strokeLinejoin="round"
                points="11.3028571 42.9774879 19.5828571 43 23 19 11.3028571 19 11.6971429 19 0 19 3.41714286 43 11.6971429 42.9774879"
              />
              <line x1="5" y1="23" x2="7" y2="40" id="Stroke-8" />
            </g>
            <g id="img-folder">
              <g
                id="Icon/Folder/Red"
                transform="translate(38.500000, 38.500000) rotate(43.000000) translate(-38.500000, -38.500000) translate(5.500000, 17.500000)"
              >
                <path d="" id="Path-2" stroke="#979797" />
                <path
                  d=""
                  id="Path-4"
                  stroke="#8F0339"
                  strokeLinecap="square"
                />
                <polyline
                  id="Path-5"
                  stroke="#8F0339"
                  strokeLinecap="square"
                  points="18.8977078 31.5680921 18.8977078 10.9966636 29.8505171 10.9966636 29.8505171 13.6035 46.3262792 13.6035 46.3262792 16.2378528 46.3262792 16.2378528"
                />
                <polygon
                  id="Rectangle"
                  stroke="#8F0339"
                  strokeLinecap="square"
                  points="21.6000596 16.1395207 49.7548506 16.1395207 47.0524988 31.5680921 18.8977078 31.5680921"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FolderInTrash;
