import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TagRed = props => {
  return (
    <SvgIcon width={13} height={12} viewBox="0 0 13 12" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon/Tag/Active"
          transform="translate(-2.000000, 0.000000)"
          stroke="#8F0339"
        >
          <g id="Group-3-Copy-2" transform="translate(3.000000, 1.000000)">
            <polygon
              id="Path-13"
              points="11 6.381399 5.18989891 0.57129791 2.57841918 -3.81639165e-17 1.55539791 1.02302127 1.58546321 0.992955972 0 2.57841918 0.57129791 5.18989891 6.381399 11 8.98695312 8.39444588"
            />
            <circle
              id="Oval"
              transform="translate(3.000000, 3.000000) rotate(-45.000000) translate(-3.000000, -3.000000) "
              cx="3"
              cy="3"
              r="1"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default TagRed;
