import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementText = props => {
  const { htmlColor } = props;
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <rect
        width="0.5"
        height="7"
        rx="0.25"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 2.5 11.7812)"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        width="0.500001"
        height="7"
        rx="0.25"
        transform="matrix(1 1.93141e-09 -2.02069e-09 -1 5.78125 15.5)"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M11.8066 19L14.25 7.46875L10.6641 7.46875L10.9453 6.15039L19.6113 6.15039L19.3477 7.46875L15.7617 7.46875L13.3096 19L11.8066 19ZM21.2725 19.1758C20.6338 19.1758 20.1006 19.0234 19.6729 18.7188C19.251 18.4082 19.04 17.8779 19.04 17.1279C19.04 16.9463 19.0518 16.75 19.0752 16.5391C19.0986 16.3223 19.1367 16.0996 19.1895 15.8711L20.3232 10.4834L18.8379 10.4834L18.9785 9.80664L20.5781 9.19141L21.6328 7.15234L22.5029 7.15234L22.0195 9.36719L24.498 9.36719L24.2695 10.4834L21.7998 10.4834L20.6572 15.8887C20.6162 16.0527 20.5781 16.2285 20.543 16.416C20.5137 16.6035 20.499 16.7822 20.499 16.9521C20.499 17.2451 20.5752 17.4912 20.7275 17.6904C20.8799 17.8838 21.1348 17.9805 21.4922 17.9805C21.7148 17.9805 21.9258 17.96 22.125 17.9189C22.3301 17.8779 22.5469 17.8193 22.7754 17.7432L22.7754 18.8682C22.6289 18.9385 22.4062 19.0059 22.1074 19.0703C21.8145 19.1406 21.5361 19.1758 21.2725 19.1758Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ElementText;
