import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PencilRed = props => {
  return (
    <SvgIcon
      fontSize="small"
      width={20}
      height={20}
      viewBox="0 0 20 20 "
      {...props}
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="29" height="29" />
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon/Personalize/Grey"
          transform="translate(-6.000000, -5.000000)"
        >
          <g id="Background" />
          <g
            id="Group-323423432"
            mask="url(#mask-23242342)"
            stroke={props.htmlColor || '#8F0339'}
            strokeOpacity="0.5"
          >
            <g transform="translate(5.000000, 5.000000)">
              <polygon
                id="Path-33434"
                strokeLinecap="round"
                transform="translate(10.000000, 10.500000) rotate(-315.000000) translate(-10.000000, -10.500000) "
                points="9.9382019 -1 8 -1 8 1.53502102 8 16.9027027 9.9382019 22 12 16.9027027 12 -1"
              />
              <line
                x1="13"
                y1="6.43181818"
                x2="16.8396116"
                y2="6.50871031"
                id="Path-4"
                transform="translate(15.500000, 6.500000) rotate(-315.000000) translate(-15.500000, -6.500000) "
              />
              <line
                x1="2"
                y1="17.5"
                x2="4"
                y2="17.5"
                id="Line-4"
                transform="translate(3.000000, 17.500000) rotate(-315.000000) translate(-3.000000, -17.500000) "
              />
              <line
                x1="3"
                y1="15.5272457"
                x2="7"
                y2="15.4727543"
                id="Line-5"
                transform="translate(5.000000, 15.500000) rotate(-315.000000) translate(-5.000000, -15.500000) "
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default PencilRed;
